import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import CONFIG from 'src/app/app.config';
import { ComponentService } from 'src/app/services/component/component.service';
import { FeedbackComponent } from '../../feedback/feedback.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() showComponent: Boolean;
  year = "2021";

  activeModalsRef : NgbModalRef[] = [];
  modalContainerSelector = CONFIG.APPLICATION.ADDITIONAL.MODAL_SELECTION_PARENT;
  socialMedia = CONFIG.APPLICATION.URL.SOCIAL_MEDIA;

  constructor(private modalService: NgbModal,
    private modalConfig : NgbModalConfig,
    private componentService: ComponentService) {

      this.componentService.setModalConfig(this.modalConfig, this.modalContainerSelector)
      let self = this;
      this.modalService.activeInstances.subscribe( (list) => {
        self.activeModalsRef.push(...list);
      });
    }

  ngOnInit(): void {

  }

  //Feedback details
  openFeedback() {
    window['appzi'].openWidget(CONFIG.APPLICATION.FEEDBACK.WIDGET_ID);
  }

  closeFeedback() {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
  }

}
