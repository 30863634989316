import { Injectable } from "@angular/core";
import { Tree } from "../models/tree";

@Injectable()
export class D3TreeService {
    treeModel : Tree = new Tree();

    constructor() {}

    createChart(chartContainer: any, treeData: any): void {
        if(! chartContainer) {
            console.error("Failed to locate chart container");
            return;
        }

        this.treeModel.addSvgToContainer(chartContainer);
        this.treeModel.createLayout();
        this.treeModel.createTreeData(treeData);

    }

    update() {
        this.treeModel.update(this.treeModel.root);
    }

    setNodeChangedListener(callable) {
        this.treeModel.nodechanged = callable;
    }
    setNodeSelectedListener(callable) {
        this.treeModel.nodeselected = callable;
    }
    addNode(node: any) {
        this.treeModel.addNode(node);
    }

    centerNode(datum: any) {
        this.treeModel.triggerClickEvent(datum)
    }

}