<!-- The menu options -->
<app-menu-nav [showComponent]="showMenu.nav" [step]="sectionTitle" [start]="showMenu.startLabel">
</app-menu-nav>

<!-- Then handler for each path -->
<router-outlet></router-outlet>

<!-- Use as container for all the modals we are using -->
<div class="modal-container"></div>

<!-- Footer -->
<app-footer [showComponent]="showMenu.footer"></app-footer>

<app-cookie></app-cookie>

<!-- Loading section -->
