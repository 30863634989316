import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import CONFIG from 'src/app/app.config';
import { Router } from '@angular/router';
import { AuthGuardService } from 'src/app/services/auth-guard/auth-guard.service';
import { AuthenticateService } from 'src/app/services/authenticate/authenticate.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';

declare const jQuery: any;

@Component({
  selector: 'app-menu-nav',
  templateUrl: './menu-nav.component.html',
  styleUrls: ['./menu-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuNavComponent implements OnInit, OnChanges{
  isLoggedIn: Boolean;
  urlPath = CONFIG.APPLICATION.URL;
  alias = CONFIG.APPLICATION.ANALYTICS.SCREENS.AUTHENTICATION;
  appName = CONFIG.APPLICATION.META_DATA.TITLE;
  socialMedia = CONFIG.APPLICATION.URL.SOCIAL_MEDIA;

  @Input() 
  showComponent: Boolean;
  @Input()
  step: String;
  @Input()
  start: String;
  

  constructor(private router: Router, 
    private authenticateService : AuthenticateService,
    private analytics : AngularFireAnalytics) { 
      this.isLoggedIn = this.authenticateService.isAuth();
    }
    
  ngOnInit(): void {
    this.jsCustomScript();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    //throw new Error('Method not implemented.');
    if(!! changes.showComponent) {
      this.showComponent = !! changes.showComponent.currentValue;
    }

    if(!! changes.step) {
      this.step = changes.step.currentValue;
    }
  }
  
  logout() {
    this.isLoggedIn = this.authenticateService.invalidateAuth();
    this.analytics.logEvent(`${this.alias}-logout`)

    window.location.reload();
  }

  goTo(path : string) {
    // this.router.navigate([path]);
    this.analytics.logEvent(`${this.alias}-${path}`)
    window.location.href = path;    
  }

  jsCustomScript() {
    (function ($) {
      $(document).ready(function(){
        $('[data-toggle="offcanvas"], .nav-item a:not(.dropdown-toggle)').on('click', function () {
          $('.offcanvas-collapse').toggleClass('open')
      });
      });
    })(jQuery);
  }

}
