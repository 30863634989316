import { Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import CONFIG from 'src/app/app.config';
import { Feedback } from 'src/app/d3/models/feedback';
import { User } from 'src/app/d3/models/user';
import { AnalyticsService } from 'src/app/services/tracking/analytics.service';
import { UserdataService } from 'src/app/services/user/userdata.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, OnDestroy {
  private screenName = CONFIG.APPLICATION.ANALYTICS.SCREENS.FEEDBACK;  
  private actions = CONFIG.APPLICATION.ANALYTICS.ACTIONS;
  visible: Boolean = false;
  appVersion = CONFIG.RELEASE.VERSION;

  @Output()
  onClose: EventEmitter<any> = new EventEmitter();

  maxlength: number = 50;
  feedbackForm: FormGroup;
  submitted:Boolean = false;
  userData: User;
  
  constructor(private formBuilder: FormBuilder,
    private element : ElementRef,
    private userService: UserdataService, 
    private analytics: AnalyticsService) {

      this.feedbackForm = this.formBuilder.group({
        rating: [],
        message: [Validators.maxLength(this.maxlength)]
      });

      this.feedbackForm.get("rating").setValue("");
      this.feedbackForm.get("message").setValue("");
      this.analytics.setCurrentScreen(this.screenName);
  }

  ngOnInit(): void {
    this.submitted = false;
  }

  ngOnDestroy() {
    this.visible = false;
    this.resetForm();
  }

  canOpenFeedback(force?: Boolean): Boolean {
    //Show if there are no feedback. IF member select maybe later still
    this.userData = this.userService.retrieveLocalData(); 
    let feedbacks = this.userData?.feedbacks || [];
    let self = this;
    

    if(! feedbacks || feedbacks.length == 0 || force == true) {
      this.visible = true;
    }
    
    feedbacks.forEach( (fb : Feedback) => {
      //If not optOut and version upgrade has occured, ask for feedback
      if (! fb.optOut && self.appVersion != fb.version) {
        this.visible = true;
      }
    });

    return this.visible;
  }

  close() {
    this.onClose.emit();
  }

  maybeLater() {
    if(! this.userData) {
      this.userData = this.userService.retrieveLocalData();
    }
    
    if(!! this.userData) {
      let feedback = new Feedback(this.userData.userId, this.appVersion, true);
      this.userData.feedbacks.push(feedback);
    }
    
    this.close();
    
    let eventName = this.analytics.SELECT_CONTENT;
    let eventDetails = this.analytics.buildPageSelectContentParams(`${this.screenName}`, `${this.actions.CLOSE}`);
    this.analytics.logEvent(eventName, eventDetails);
  }

  submitFeedback() {
    if(this.feedbackForm.invalid) {
      return;
    }

    this.submitted = true;

    //Call the endpoint to submit feedback.
    let rating: number = Number(this.feedbackForm.get("rating").value);
    let message = this.feedbackForm.get("message").value;
    let feedback = new Feedback(this.userData.userId, this.appVersion, false, rating, message);

    this.userData.feedbacks.push(feedback);
    this.userService.updateLocalData(this.userData);
  }

  resetForm() {
    this.feedbackForm.reset();
    this.submitted = false;
  }

}

