import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { NgbActiveModal, NgbCollapseModule, NgbDropdownModule, NgbModule } from 'node_modules/@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { DraggableDirective } from './d3/directives/draggable.directive';

import { D3Service, D3_DIRECTIVES } from './d3';
import { GraphComponent } from './visuals/graph/graph.component';
import { SHARED_VISUALS } from './visuals/shared';
import { D3TreeService } from './d3/services/d3-tree.service';
import { TreeVisualComponent } from './visuals/tree/tree.component';
import { StepDetailsComponent } from './visuals/step-details/step-details.component'
import { StepTemplateComponent } from './visuals/step-template/step-template.component';
import { PathService } from './d3/services/path.service';
import { StepService } from './d3/services/step.service';
import { ChoiceTemplateComponent } from './visuals/choice-template/choice-template.component';
import { DecisionWizardComponent } from './visuals/decision-wizard/decision-wizard.component';
import { DecisionService } from './d3/services/decision.service';
import { PathNavigationComponent } from './visuals/path-navigation/path-navigation.component';
import { PathSummaryComponent } from './visuals/path-summary/path-summary.component';
import { WelcomeComponent } from './visuals/welcome/welcome.component';
import { NotFoundComponent } from './visuals/not-found/not-found.component';
import { NgPipesModule } from 'ngx-pipes';
import { PathComponent } from './visuals/path/path.component';
import { AuthComponent } from './visuals/auth/auth.component';
import { FooterComponent } from './visuals/footer/footer/footer.component';
import { MenuNavComponent } from './visuals/menu-nav/menu-nav.component';
import { SummaryCollapserDirective } from './directives/summary-collapser/summary-collapser.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReCaptchaModule } from 'angular-recaptcha3';
import { FeedbackComponent } from './visuals/feedback/feedback.component';
import { DecisionResolver } from './services/resolver/decision-resolver';
import { PathResolver } from './services/resolver/path-resolver';
import { PathIntroComponent } from './visuals/path-intro/path-intro.component';
import { CookieComponent } from './visuals/cookie/cookie.component';
import { CookieService } from 'ngx-cookie-service';
import { CookiePolicyComponent } from './visuals/cookie-policy/cookie-policy.component';
import { AdvertisementService } from './services/advertisement/advertisement.service';
import { GoodbyeComponent } from './visuals/goodbye/goodbye.component';
@NgModule({
  declarations: [
    AppComponent,
    DraggableDirective,
    GraphComponent,
    TreeVisualComponent,
    ...SHARED_VISUALS,
    ...D3_DIRECTIVES,
    StepDetailsComponent,
    StepTemplateComponent,
    ChoiceTemplateComponent,
    DecisionWizardComponent,
    PathNavigationComponent, //Handles the navigation
    PathSummaryComponent, 
    WelcomeComponent, 
    NotFoundComponent, 
    PathComponent, 
    AuthComponent, 
    FooterComponent, 
    MenuNavComponent, 
    SummaryCollapserDirective, 
    FeedbackComponent, PathIntroComponent, CookieComponent, CookiePolicyComponent, GoodbyeComponent //Summarizes everything at the end
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule, //Used as part of the reactive form group
    //Bootstrap modules
    NgbModule, //This is all the libaries in this module
    //NgbPaginationModule,
    NgbDropdownModule, //For the dropdown module
    NgbCollapseModule,
    NgPipesModule, //For the reverse array pipe used in the path-summary
    
    AppRoutingModule,
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,

    HttpClientModule,
    ReCaptchaModule.forRoot(environment.captchaConfig.options)
  ],
  entryComponents: [StepDetailsComponent],
  providers: [
    DecisionResolver,
    PathResolver,
    PathService,
    D3Service, 
    D3TreeService,
    NgbActiveModal,
    StepService,
    DecisionService,
    CookieService,
    AdvertisementService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
