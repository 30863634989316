<div class="footer mt-5 pt-5" *ngIf="showComponent == true">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="footer-col first">
                    <p class="p-small">
                        <img src="../../../assets/images/icons/logo-symbols.svg" alt="alternative">
                    </p>
                    <small>&copy; IdealCareerPath</small>
                </div> <!-- end of footer-col -->
                <div class="footer-col second">
                    <p class="p-small"><a href="/terms-policy">Terms &amp; Privacy Policy</a></p>
                    <p class="p-small"><a (click)="openFeedback()">Feedback</a></p>
                </div> 
                <!-- end of footer-col -->
                <div class="footer-col third">
                    <span class="fa-stack">
                        <a href="{{socialMedia.TWITTER}}">
                            <img src="../../../../assets/images/icons/twitter.svg" alt="Twitter" loading="lazy"/>
                        </a>
                    </span>
                    <!-- <span class="fa-stack">
                        <a href="{{socialMedia.FACEBOOK}}">
                            <img src="../../../../assets/images/icons/facebook.svg" alt="Social Media Facebook" loading="lazy" />
                        </a>
                    </span> -->
                    <span class="fa-stack">
                        <a href="{{socialMedia.INSTAGRAM}}">
                            <img src="../../../../assets/images/icons/instagram.svg" alt="Social Media Instagram" loading="lazy" />
                        </a>
                    </span>
                    <span class="fa-stack">
                        <a href="#your-link">
                            <i class="fas fa-circle fa-stack-2x"></i>
                            <i class="fab fa-instagram fa-stack-1x"></i>
                        </a>
                    </span>
                </div> <!-- end of footer-col -->
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of footer -->  