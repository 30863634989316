<div class="cookie-policy" >
    <header class="ex-header">
        <div class="container">
            <div class="row">
                <div class="col col-sm-12 offset-xl-1">
                    <h1>Cookie Policy</h1>
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </header> <!-- end of ex-header -->
    <!-- end of header -->

    <div class="ex-basic-1 pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-xl-10 offset-xl-1">
                    <div class="text-box mt-5 mb-5">
                        <p>We sometimes place small data files, called cookies, or other tracking technologies on your browser. A cookie is a small text file that a website saves on your computer or mobile device when you visit a website. <strong>IdealCareerPath</strong> uses both session and persistent cookies. Session cookies are cookies that disappear from your computer or browser when you log out of your account or close your browser. Persistent cookies are stored even after you’ve closed the page.</p>
                    </div>

                    <div class="text-box mt-5 mb-5">
                        <p>
                            Keep reading to find out what those services are, how we use them, and what choices you can make about them. Most cookies can be blocked or removed from your browser at any time by following the relevant procedure specific to your browser. For example, they may contain an option to clear your browsing history or to browse incognito or in private mode.
                        </p>
                    </div>

                    <div class="text-box mt-5 mb-5">
                        <p>
                            Please note that when you block cookies on this site, the site may not function as designed. For example, you will be unable to log in where appropriate or to retain any user preferences, so information may appear in the wrong locale or be unavailable.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <header class="ex-header">
        <div class="container">
            <div class="row">
                <div class="col col-sm-12 offset-xl-1">
                    <h1>The cookies we use</h1>
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </header> <!-- end of ex-header -->
    <!-- end of header -->

    <div class="ex-basic-1 pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-xl-10 offset-xl-1">
                    <div class="text-box mt-5 mb-5">
                        <h3>NECESSARY COOKIES</h3>
                        <p>If you use our platform or our website, there are required/necessary cookies. They make it possible to authenticate users, prevent fraudulent use of login credentials, protect accounts information from unauthorized access, enable you to be able to send contact forms, subscribe to our newsletter, watch YouTube and Vimeo videos. You can not access our platform or our website without accepting these cookies.</p>
                    </div>

                    <div class="text-box mt-5 mb-5">
                        <h3>STATISTICS COOKIES</h3>
                        <p>
                            Statistic cookies help us to understand how visitors interact with websites by collecting and reporting information anonymously.
                        </p>
                    </div>

                    <div class="text-box mt-5 mb-5">
                        <h3>GOOGLE ANALYTICS/ GOOGLE TAG MANAGER</h3>
                        <p>
                            We use Google Analytics to gain insights into our website traffic and marketing effectiveness. With Google Analytics we track information such as the pages that are visited, for how long, what browser is used and from which pages users exit. This will give us a better understanding of how our website is used. It also serves as guidance to improve our website and its specific pages.
                        </p>
                    </div>
                    <div class="text-box mt-5 mb-5">
                        <h3>MARKETING COOKIES</h3>
                        <p>
                            We use third-party providers to connect with Users that have already shown interest in our services (by visiting our website before) and new users when they search for terms related to our products and services.
                        </p>
                    </div>

                    <div class="text-box mt-5 mb-5">
                        <p>
                            <strong>IdealCareerPath</strong> sometimes uses cookies placed by its third-party service providers to track the performance of our advertisements. For example, these cookies remember which browsers have visited our site. The data we send to our third parties does not include information that personally identifies you, but it might be re-associated with information that identifies you specifically after Biller receives it.To learn more about and how you can opt-out of targeting and advertising cookies, you can visit this link if you are in the US or this link if you are in the European Union.
                        </p>
                    </div>

                    <div class="text-box mt-5 mb-5">
                        <h3>The Targeting & Advertising Cookies we use are:</h3>
                        <p><strong>Facebook</strong> You’ll know them from, well, Facebook (and Instagram). With their pixel, we can retarget you with ads on their channels (mainly Facebook & Instagram)</p>
                    
                    <div class="text-box mt-5 mb-5">
                        <p><strong>LinkedIn</strong> We use the LinkedIn Insights Pixel so that we can retarget you with ads while you are on their website.</p>
                    </div>

                    <div class="text-box mt-5 mb-5">
                        <p><strong>Google AdWords Conversion</strong> We fortify our advertising efforts by using Google AdWords remarketing tag (advertising cookies). The information it collects is restricted to the pages you have visited on our domain.For more information on opting out of Google advertising tracking technologies, click here. You can opt-out from Google Ads here.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>