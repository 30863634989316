import { StepListItem } from './stepListItem';
import { Supplimental } from './supplimental';


export class Step {
   public static readonly STEP_TYPE_ADV = "advertisement";

   title: String;
   image: String;
   description: String;
   objective: String; //Used to provide tooltip information
   list: StepListItem[];
   type: String;
   summary: String;
   supplimentals: Supplimental[];
   
   
   constructor(type, title, image, description, objective, list, supplimentals, summary?) {
      this.type = type;
      this.title = title;
      this.image = image;
      this.description = description;
      this.objective = objective;
      this.list = list;
      this.summary = summary;
      this.supplimentals = supplimentals
   }

}