import { Injectable, OnInit } from '@angular/core';

import { FirebaseApp } from '@angular/fire';
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import { environment } from '../../../environments/environment';

firebase.initializeApp(environment.firebaseConfig);

@Injectable({
  providedIn: 'root'
})
export class FirebaseService{

  constructor() {
      console.log("Firebase init")
   }


  db(table: any) {
    return firebase.database().ref(table);
  }
  create(table: any, data: object) {
    this.db(table).push(data);
  }

  remove(segment : any) {
    return firebase.database().ref(segment).remove();
  }

}
