<!-- <div class="cc-component-container" id="decision-backdrop"> -->
    <div class="cc-top text-center">
        
        <h2>Choose Your Interest</h2>
    </div>
    <div class="decision-content cc-content">
        <ngb-carousel #decisionWizard>
            <ng-template *ngFor="let decision of decisions;" ngbSlide>
                <div class="decision-splash-wrapper mb-md-5 text-center">
                    <div class="center-splash">
                        <img class="decision-splash" src="assets/images/icons/{{decision.image}}" alt="Decision image" loading="lazy">
                    </div>
                    <div class="text-center">
                        <h6 class="my-3">{{decision.question}} 
                            <span class="cc-tooltip mx-3" *ngIf="decision.description != ''" 
                                ngbTooltip="{{decision.description}}" [autoClose]="true"
                                triggers="click" >&nbsp;</span>
                        </h6>
                        <!-- The dropdown selection -->
                        <!-- <span>{{decision.filler}} </span>   -->
                        <div ngbDropdown 
                            #decisionDrop="ngbDropdown" 
                            class="dropdown-wrap mb-5" 
                            placement="top bottom">
                                
                            <button ngbDropdownAnchor 
                                class="btn {{noError == true ? 'btn-primary' : 'btn-danger'}}" 
                                id="decisionOptions" 
                                (click)="decisionDrop.open()">{{decision.selectedOption != undefined? decision.selectedOption.title : 'Select Option' }}</button>
                                
                                <div ngbDropdownMenu 
                                    arial-labelledby="decisionOptions"
                                    class="text-center">
                                    <!-- <ng-template *ngFor="let choice of decisionChoices"> -->
                                    
                                        <ng-container *ngFor="let choice of decision.choices">
                                            <button class="dropdown-item" 
                                                *ngIf="choice.enable" 
                                                (click)="makeDecision(decision, choice)">{{choice.title}}</button>
                                        </ng-container>
                                    <!-- </ng-template> -->
                                </div>
                        </div>
                    </div>
                    
                </div>
            </ng-template>
        </ngb-carousel>
        <div class="row cc-lower">
            <button class="col col-sm-5 col-md-4 btn btn-secondary" 
                *ngIf="firstPage == true"  
                (click)="idkHelp()">Not Sure</button>
            
            <button class="col col-sm-5 col-md-4 btn btn-secondary" 
                *ngIf="firstPage != true"    
                (click)="previous()">Previous</button>    
            <div class="col col-sm-2 col-md-4">
                &nbsp;
            </div>
    
            <button class="col col-sm-5 col-md-4 btn btn-primary" *ngIf="finalPage == true"
                (click)="complete()">Start</button>
    
            <button class="col col-sm-5 col-md-4 btn btn-primary" *ngIf="finalPage != true"
                (click)="next()">Next</button>
        </div>
    </div>
<!-- </div> -->