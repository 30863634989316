import { Injectable, OnInit } from '@angular/core';
import CONFIG from 'src/app/app.config';
import { FirebaseService } from 'src/app/d3/services/firebase.service'

@Injectable({
  providedIn: 'root'
})
export class PathService implements OnInit {    
  private pathMaps : [];
  private promise : Promise<Object>;
  private version : String = CONFIG.RELEASE.VERSION;
  
  constructor(private fbService : FirebaseService) {
    console.log("Initializing path service with services");
  }

  ngOnInit() {
    console.log("Init: ", this.pathMaps)
  }

  getList(section?: String) {
    var dbName = section;
    var itemsRef = this.fbService.db(`${this.version}/${dbName}`);
    var that = this;

    this.promise = new Promise(function(resolve, reject) {    
      itemsRef.orderByValue().on('value', (snapshot) => {
        let items = snapshot.val();

        if(! items) {
          reject("Failed to obtain values from service")
        }

        resolve(items)
      })
    })

    return this.promise
  }

  getPathMaps() {
    console.log("THIS GETPATHMAPS: ", this.pathMaps)
    return this.pathMaps;
  }


  /**
   * //TODO: create a list of sections where updates are allowed
   * @param section 
   * @param data 
   * @returns 
   */
  update(section: String, data: any) : Promise<any> {
    let parsedData = JSON.parse(JSON.stringify(data));
    let dbRef = this.fbService.db(`${this.version}/${section}`);

    return dbRef.update(parsedData);
  }

  /**
   * This addes a new data and returns the key
   * @param section 
   * @param data 
   * @returns 
   */
  push(section: String, data: any) : String{
    let parsedData = JSON.parse(JSON.stringify(data));
    let dbRef = this.fbService.db(`${this.version}/${section}`);

    return dbRef.push(parsedData)?.key;
  }
  
}
