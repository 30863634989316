import { Component, OnInit, Input } from '@angular/core';
import { Step } from 'src/app/d3/models/step';
import { Supplimental } from 'src/app/d3/models/supplimental';

@Component({
  selector: 'app-step-template',
  templateUrl: './step-template.component.html',
  styleUrls: ['./step-template.component.scss',
              //'../../../styles/shared/css/shared-styles.css'
            ]
})
export class StepTemplateComponent implements OnInit {
  @Input() type: string;
  @Input() heading: string;
  @Input() jobTitle: string;
  @Input() previewMode: Boolean;
  @Input() detailExplanation: string;
  @Input() detailItems: Array<any>;
  @Input() supplimentals: Array<any>; //The list of sponsors we need to pull from external requests
  @Input() boldHeadingFirstWord: Boolean = false;
  @Input() splashImage: string;
  @Input() toolTipText: string;
  @Input() summaryTooltipText: string;
  
  fullSplashPath: string;
  splashImageAlt: string = 'The splash image is not found';
  advType = Step.STEP_TYPE_ADV;

  constructor() {
    this.summaryTooltipText = "Welcome test";
  }

  ngOnInit() {
    this.fullSplashPath = '/assets/splash/' + this.splashImage;
  }

  boldFirstWord(phrase: String) {
    if(this.boldHeadingFirstWord) {
      return phrase.replace(/(\\w+)(\\s.*)/g, '<span class="bold">/</span>');
    }
    return ""
  }

}
