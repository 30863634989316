import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { ActivatedRoute } from '@angular/router';
import { NgbAlert, NgbCollapse, NgbCollapseConfig, NgbModal, NgbModalConfig, NgbModalRef } from 'node_modules/@ng-bootstrap/ng-bootstrap';
import CONFIG from 'src/app/app.config';
import { Decision } from 'src/app/d3/models/decision';
import { Feedback } from 'src/app/d3/models/feedback';
import { Motivator } from 'src/app/d3/models/motivator';
import { Summary } from 'src/app/d3/models/summary';
import { User } from 'src/app/d3/models/user';
import { PathService } from 'src/app/d3/services/path.service';
import { ComponentService } from 'src/app/services/component/component.service';
import { UserdataService } from 'src/app/services/user/userdata.service';
import { FeedbackComponent } from '../feedback/feedback.component';

@Component({
  selector: 'app-path-summary',
  templateUrl: './v2/path-summary.component.html',
  styleUrls: ['./v2/path-summary.component.scss'],
  providers: [NgbCollapseConfig, NgbCollapse],
  host: {
    'class':  'container cc-component-container', 
    'id': 'path-summary-backdrop'
  },
})
export class PathSummaryComponent implements OnInit {
  private screenName = CONFIG.APPLICATION.ANALYTICS.SCREENS.SUMMARY;
  private appVersion = CONFIG.RELEASE.VERSION;
  socialMedia = CONFIG.APPLICATION.URL.SOCIAL_MEDIA;

  // visible: Boolean = false;
  motivatorOptions: Motivator[] = [
    new Motivator("Parent"), 
    new Motivator("Teacher"),
    new Motivator("Friends"),
    new Motivator("Others")];
  savedSessionName = CONFIG.APPLICATION.ADDITIONAL.SAVE_SESSION_NAME;
  urlPath = CONFIG.APPLICATION.URL;
  summaryIdParam = this.urlPath.PARAMS.SUMMARY_ID;
  restartPath = this.urlPath.DECISION;
  summaryPath = CONFIG.APPLICATION.PATHS.SUMMARY;
  selectedOption: Motivator;
  traversedPath = [];
  decisions: Decision[];
  menuOptions = [
  {
    title: 'Twitter',
    action: 'share',
    logo: '../../../../assets/images/icons/twitter-x20.png'
  }, 
  {
    title: 'Instagram',
    action: 'share',
    logo: '../../../../assets/images/icons/instagram-x20.png'
  }];
  selectedMenuOption = undefined;
  alertMessage: String;
  showAlert: Boolean = false;
  analyticsConfigs = CONFIG.APPLICATION.ANALYTICS;
  errorState: Boolean = false;

  //Feedback details
  activeModalsRef : NgbModalRef[] = [];
  modalContainerSelector = CONFIG.APPLICATION.ADDITIONAL.MODAL_SELECTION_PARENT;
  sessionData : User;
  feedbackSubmitted: Boolean = false;
  additionalInput: Boolean = false;
  
  @ViewChild('summaryAlert', {static: false}) summaryAlert: NgbAlert;

  constructor(private route: ActivatedRoute,
      private userDataService: UserdataService,
      private pathService: PathService,
      private analytics : AngularFireAnalytics,
      private modalService: NgbModal,
      private modalConfig : NgbModalConfig,
      private componentService: ComponentService,
      @Inject(DOCUMENT) private document: Document) { 

        this.componentService.setModalConfig(this.modalConfig, this.modalContainerSelector);

        let self = this;
        this.modalService.activeInstances.subscribe( (list) => {
          self.activeModalsRef.push(...list);
        });
      }

  ngOnInit(): void {
    let self = this;
    this.route.params.subscribe( (params) => {

      let summaryPath = `${this.summaryPath}/${params[this.summaryIdParam]}`;

      this.pathService.getList(summaryPath).then((resp) => {
        let summary = new Summary(resp['enable'], resp['decisions'], resp['traversed']);
        self.setData(summary.traversed, summary.decisions);
        this.sessionData = this.userDataService.retrieveLocalData();
      }, (err) => {
        //Fail to load the page with information
        self.errorState = true;
        console.error(`Fail to load important resource: ${params}`, err);
      });
    });

    this.analytics.setCurrentScreen(this.screenName);
  }

  setData(path, decisions: Decision[]) {
    this.traversedPath = path;
    this.decisions = decisions;
  }

  chooseMotivator(motivator: Motivator) {
    this.selectedOption = motivator;
    
    let analyticsParam = {};
    analyticsParam[this.analyticsConfigs.LOG_PARAM] = motivator.title;
    this.analytics.logEvent(`${this.analyticsConfigs.SCREENS.SUMMARY}-${this.analyticsConfigs.ACTIONS.MOTIVATOR}`, analyticsParam);
  }

  share(platform, target = '') {
    let url = this.socialMedia[platform.toUpperCase()];
    window.open(url, target);
  }

  save() {
    this.sessionData = this.userDataService.retrieveLocalData();
    console.log("Attempting to pull data for user: ", this.sessionData);
    let resp : Promise<any> = this.userDataService.setTraversedPath(this.sessionData.userId, this.traversedPath);
    resp.then((success) => {
      console.log("Saving the user details complete without error: ", success);

      
      this.alertMessage = "User details saved";
      this.showAlert = true;

      // this.openFeedback(); //This is random based on a few requirements.

      setTimeout(() => { 
        this.showAlert = false; 
      }, 5000);
    }, (err) => {
      console.error("Failed to complete the save operation due to error: ", err);

      this.alertMessage = "Ooops! Save failed.";
      this.showAlert = true;

      setTimeout(() => { this.showAlert = false; }, 5000);
    })
  }

  restart() {
    window.location.href = this.restartPath
  }

  makeDecision(choice?:any) {
    if(!! choice) {
      this.selectedMenuOption = choice;
      let action = choice.action;
      let param = choice.title;

      this[action](param);
    }
  }

  //Feedback details
  openFeedback() {
    window['appzi'].openWidget(CONFIG.APPLICATION.FEEDBACK.WIDGET_ID);
  }

  closeFeedback() {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
  }

  /**
   *
   * Simple feedback sent to the 
   * @param helpful 
   */
  feedback(helpful: String) {
    window['appzi'].openWidget(CONFIG.APPLICATION.FEEDBACK.WIDGET_ID);
  }

  openFeedbackModal() {
    let ref = this.modalService.open(FeedbackComponent, this.modalConfig);
    let instance: FeedbackComponent = ref.componentInstance;

    if(! instance.canOpenFeedback(true)) {
      console.error(`Force open feedback form failed`);
      return false;
    }

    this.activeModalsRef.push(ref);
    
    //For each modals being tracked, find the appropriate one and call the respective method.
    this.activeModalsRef.forEach( (ref : NgbModalRef) => {
      const component = ref.componentInstance;
      if( component instanceof FeedbackComponent) {
        let self = this;
        component.onClose.subscribe(() => {
          self.closeFeedback();
        })
      }
    })
  }

}

