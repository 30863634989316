import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

var setMetaTag = function(config, attr) {
  var keys = Object.keys(config); 

  keys.forEach((key, index) => {
    var selector = `meta[${attr}='${key}']`;
    var val = config[key];
    var el = document.querySelector(selector);
    
    //Create if not found.
    if (! el || el == null) {
      el = document.createElement(`meta`);
      el.setAttribute(attr, key);
    }

    el.setAttribute("content", val);

    //Append if it doesn't exists in dom
    if(document.querySelector(selector) == null) {
      document.head.appendChild(el);
    }
  });
}

var cfgs = [
  {
    config: environment.metaConfigs.default,
    attr: "property"
  },
  {
    config: environment.metaConfigs.openGraph,
    attr: "property"
  },
  {
    config: environment.metaConfigs.twitter,
    attr: "name"
  }
]
.forEach( (c) => {
  setMetaTag(c.config, c.attr);
});

var metaDesc = document.querySelector("meta[property='description']");
var titleDes = document.querySelector("title");
var captchaConfig = environment.captchaConfig;

//Add dynamic scripts to the index.html page.
var captchaTag = document.createElement("script");
captchaTag.type = "text/javascript";
captchaTag.src = `${captchaConfig.path.jsScript}?render=${captchaConfig.publicKey}`;
document.head.appendChild(captchaTag);
titleDes.innerHTML = `${titleDes.innerHTML} ${!environment.production ? environment.label: ''}`; 

if (environment.production) {
  enableProdMode();
} else {  
  metaDesc.setAttribute("content", `${metaDesc.getAttribute("content")}  - ${environment.label}`);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
