
export class StepListItem {
    enable: Boolean = false;
    name: String;
    subject: String;
    description: String;
    icon: String = "star";

    constructor(name, subject, description, icon, enable) {
        this.name = name;
        this.subject = subject;
        this.description = description;
        this.icon = icon;
        this.enable = enable;
    }
   
}

