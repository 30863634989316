import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { PathService } from 'src/app/d3/services/path.service';
import APP_CONFIG from 'src/app/app.config';
import { DecisionService } from 'src/app/d3/services/decision.service';

@Injectable({
  providedIn: 'root'
})
export class DecisionResolver implements Resolve<any>{
  private readonly decisionPath: String = APP_CONFIG.APPLICATION.DECISION.ROOT;


  constructor(private pathService: PathService,
    private decisionService: DecisionService) { }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.pathService.getList(this.decisionPath)

    // this.pathService.getList(this.decisionPath).then(function(results){
    //   let decisions: Decision[] = this.decisionService.mapValuesToDecision(results);
    //   route.data = decisions;          
    // });
  }
}
