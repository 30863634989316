import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss'],
  host: {
    'class': 'container cc-component-container',
    'id': 'cc-cookie-policy'
  }
})
export class CookiePolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
