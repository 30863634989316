import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import CONFIG from 'src/app/app.config';
import { User } from 'src/app/d3/models/user';
import { AuthenticateService } from 'src/app/services/authenticate/authenticate.service';
import { UserdataService } from 'src/app/services/user/userdata.service';
import firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  host: {
    'class':  'container cc-component-container', 
    'id': 'auth-component-backdrop'
  },
})
export class AuthComponent implements OnInit {
  private readonly autoLogout = CONFIG.APPLICATION.URL.PARAMS.AUTO_LOGOUT;
  private readonly urls = CONFIG.APPLICATION.URL; 
  isLoggedIn: Boolean; 
  checkEmail: Boolean;
  appName: String = CONFIG.APPLICATION.META_DATA.TITLE;
  alias = CONFIG.APPLICATION.ANALYTICS.SCREENS.AUTHENTICATION;
  userDetails : User;
  email: string;
  signInForm: FormGroup;
  enableEmailAuth: Boolean = environment.enableEmailAuth;


  urlPaths = CONFIG.APPLICATION.URL;
  
  constructor(private authenticateService : AuthenticateService,
    private analytics : AngularFireAnalytics, 
    private userDataService: UserdataService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal) { 
    this.isLoggedIn = this.authenticateService.isAuth();

    this.signInForm = this.formBuilder.group({
      email: []
    });

    if(! this.isLoggedIn) {
      if(this.userSignInWithEmailLink()) { //If user click the confirmation email.
        this.proceedWithLoginEmailUser();
        return;
      } 
      this.authenticateService.execAuth();
    } else {
      this.userDetails = this.userDataService.retrieveLocalData();
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe( (params) => {

      //Used to autologout users when done via apps or various redirects.
      let autoLogout = !! params[this.autoLogout];
      if(autoLogout) {
        this.logout();
      }
    })
    
  }

  logout() {
    this.isLoggedIn = this.authenticateService.invalidateAuth();
    this.analytics.logEvent(`${this.alias}-logout`)

    window.location.replace(`${this.urls.AUTH}`);
  }

  goTo(path : string) {
    this.analytics.logEvent(`${this.alias}-${path}`)
    window.location.href = path;    
  }


  sendRegistrationLink() {
    var email = this.signInForm.get("email").value;
    var authSettings: firebase.auth.ActionCodeSettings = {
      url: environment.domain,
      handleCodeInApp: true
    };
    
    firebase.auth().sendSignInLinkToEmail(email, authSettings).then( (resp) => {
      window.localStorage.setItem('emailForSignIn', email);
      this.checkEmail = true;
    }).catch((error) => {
      console.error("Send email error: ", error);
    }) 
  }

  userSignInWithEmailLink(): Boolean {
    return (firebase.auth().isSignInWithEmailLink(window.location.href))
  }

  openAlert(content) {
    this.modalService.open(content);
  }

  proceedWithLoginEmailUser() {
    var email = window.localStorage.getItem("emailForSignIn");
    if(! email) {
      email = window.prompt("Please enter email for confirmation");
    }
    var that = this;
    firebase.auth().signInWithEmailLink(email, window.location.href).then((result) => {
      console.log("SignIn details: ", result);
      window.localStorage.removeItem("emailForSignIn");
      that.authenticateService.setAuthDetails(result);
      window.location.reload();
    }).catch((error) => {
      console.error("Failed to complete user login: ", error);
      window.localStorage.removeItem("emailForSignIn");
    })
  }
}

