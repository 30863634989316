<nav class="navbar navbar-expand-lg fixed-top navbar-light" *ngIf="showComponent == true">
    <div class="container">
        
        <!-- Text Logo - Use this if you don't have a graphic logo -->
        <!-- <a class="navbar-brand logo-text page-scroll" href="index.html">Kora</a> -->

        <!-- Image Logo -->
        <a class="navbar-brand logo-image" href="/welcome">
            <img src="../../../assets/images/icons/logo.svg" alt="alternative">
            <!-- <span>&rsaquo;</span>
            <span>{{step}}</span> -->
        </a> 

        <button class="navbar-toggler p-0 border-0" type="button" data-toggle="offcanvas">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a class="nav-link page-scroll" [routerLink]="[ urlPath.AUTH ]">Profile</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link page-scroll" href="#features">Discover</a>
                </li> -->
            </ul>
            <span class="nav-item">
                <a class="btn-solid-sm page-scroll" [routerLink]="[ urlPath.DECISION ]">{{start}}</a>
            </span>

            <div class="d-sm-block d-lg-none fixed-bottom border-top">
                <div class="menu-social-links d-flex justify-content-center p-3">
                    <a class="px-3"
                        href="{{socialMedia.TWITTER}}">
                        <img src="../../../../assets/images/icons/twitter.svg" alt="Social Media Twitter"/>
                    </a>
                    <a class="px-3"
                        href="{{socialMedia.INSTAGRAM}}">
                        <img src="../../../../assets/images/icons/instagram.svg" alt="Social Media Instagram" />
                    </a>
                    
                </div>
            </div>
        </div> <!-- end of navbar-collapse -->
    </div> <!-- end of container -->
</nav> <!-- end of navbar -->
