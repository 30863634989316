import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import firebase from 'firebase';
import * as firebaseui from 'firebaseui';
import { User } from 'src/app/d3/models/user';
import APP_CONFIG from '../../app.config'
import { UserdataService } from '../user/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {

  private fbUI : firebaseui.auth.AuthUI;
  private uiConfig: firebaseui.auth.Config;
  private session_name = APP_CONFIG.APPLICATION.AUTH.SESSION_NAME;
  private redirectAuthTo = APP_CONFIG.APPLICATION.AUTH.REDIRECT_PATH;
  private readonly redirectToParam = APP_CONFIG.APPLICATION.URL.PARAMS.REDIRECT_TO;
  private user : User = null;
  private firebaseAuth: firebase.auth.Auth;
  

  constructor(public router: Router, 
    private userDataService: UserdataService) {}

  initAuth() {
    this.setRedirectPath();
    this.setConfig();

    if(! this.fbUI) {
      this.fbUI = new firebaseui.auth.AuthUI(firebase.auth());
    }

    if(! this.firebaseAuth) {
      this.firebaseAuth = firebase.auth();
    }

  }

  /**
   * Determins if user is authenticated.
   * @returns boolean
   */
  isAuth(): boolean {
    // Check local storage
    var sessionData = this.userDataService.retrieveLocalData();
    if(!! sessionData) {
      return true
    }

    return false;
  }

  execAuth() {

    //Authenticate
    this.initAuth(); 
    
    if(! this.firebaseAuth.currentUser) {
      this.fbUI.start('#firebaseui-auth-container', this.uiConfig);
    } else {
      console.log('Current User', this.firebaseAuth.currentUser);
      this.fbUI.reset();
      return true;
    }
    
    return false
  }

  invalidateAuth(): boolean{
    //Removing session and invaliding user
    this.userDataService.removeLocalData();

    return this.isAuth();
  }

  setConfig() {
    var that = this;
    this.uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
          console.log("Sign in successful: ", authResult, redirectUrl)
          that.setAuthDetails(authResult);
          return true;
        },
        uiShown: function() {
          document.getElementById("loader").style.display= 'none';
        }
      },

      signInFlow: 'popup',
      signInSuccessUrl: that.redirectAuthTo,
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID
      ],

      //Terms of service
      //tosUrl: '/terms-of-service',

      //Privacy policy
      // privacyPolicyUrl: '/privacy-policy'

    }
  }

  defaultSuccessfulSignInResult(authResult, redirectUrl) {

  }

  transposeUser(luser) {
    console.log(luser)
    let opt: Object =  luser["additionalUserInfo"];
    let usr: Object = luser["user"];
    let tken: Object = usr['stsTokenManager']

    this.user = new User(
      usr['uid'],
      usr['displayName'],
      usr['email'],
      !!usr['accessToken']?usr['accessToken']:'',
      !! usr['refreshToken']?usr['refreshToken']:'',
      usr['emailVerified'],
      usr['photoURL'],
      opt['providerId']
    );
  } 

  setAuthDetails(session: any) {
    this.transposeUser(session);
    this.userDataService.saveLocalData(this.user)
  }

  setRedirectPath(): string {
    let currentUrl : UrlTree = this.router.parseUrl(this.router.url);
    
    if (!! currentUrl.queryParams[this.redirectToParam]) {
      this.redirectAuthTo = currentUrl.queryParams[this.redirectToParam];
    }

    return this.redirectAuthTo;
  }
}
