import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import CONFIG from 'src/app/app.config';
import { AuthenticateService } from 'src/app/services/authenticate/authenticate.service';
import { RecaptchaV3Service } from 'src/app/services/recaptcha-v3/recaptcha-v3.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: [
    './welcome.component.scss',
    './welcome-style.component.scss'
  ],
  host: {
    'class': 'cc-component-container',
    'id': 'cc-welcome'
  }
})
export class WelcomeComponent implements OnInit {
  private screenName = CONFIG.APPLICATION.ANALYTICS.SCREENS.PATHWAY;
  alias = CONFIG.APPLICATION.ANALYTICS.SCREENS.AUTHENTICATION;
  appName = CONFIG.APPLICATION.META_DATA.TITLE;
  captchaError: string;

  subscriptionSubmitted: Boolean;
  subscriptionForm: FormGroup = new FormGroup({
    subscriberEmail: new FormControl('', [Validators.required, Validators.email])
  });
  
  public urlConfig = CONFIG.APPLICATION.URL;
  
  
  constructor(private router: Router, 
      private authenticateService : AuthenticateService,
      private analytics : AngularFireAnalytics, 
      private subscriptionService: SubscriptionService,
      private reCaptchaV3Service: RecaptchaV3Service) { }

  ngOnInit(): void {
    this.analytics.setCurrentScreen(this.screenName);

    this.onChange();
  }

  goTo(path: string) {
    this.router.navigate([path]);
  }

  onChange() {
    var formElement = this.subscriptionForm.get("subscriberEmail");
    var that = this;
    formElement.valueChanges.subscribe(() => {
      that.subscriptionSubmitted = false;
    });
  }

  /**
   * Subscribe to the subscription product
   */
  onSubscribe() {
    var that = this;
    this.captchaError = null;
    var formElement = this.subscriptionForm.get("subscriberEmail");
    this.subscriptionSubmitted = true;
    formElement.markAsTouched();

    //If form is valid then you submit
    if(formElement.valid) {
      var email: String = new String(formElement.value);

      //@TODO: Validate that catpthca next steps are implemented well.
      this.reCaptchaV3Service.captchExecute().then( (captchResponse) => { //Ensure captcha is working.
        this.subscriptionService.subscribe(email).subscribe((response) => { //Submit to the subscription endpoint
          setTimeout(()=> {
            that.subscriptionSubmitted = false;
            that.subscriptionForm.reset();
          }, 5000);
        });
      }, (error) => {
        this.captchaError = "Request failed captcha.";
      });
    }
  }  
}
