import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-path-intro',
  templateUrl: './path-intro.component.html',
  styleUrls: ['./path-intro.component.scss'
  ],
  host: {
    'id': 'path-intro-backdrop'
  },
  providers: [NgbPopover]
})
export class PathIntroComponent implements OnInit, OnDestroy {

  @Input() showIntro: Boolean = false;
  @Input() title: String;
  @Input() message: String;
  
  @Output() endIntro: EventEmitter<any> = new EventEmitter();
  
  constructor() {}

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    //Do something at this state
    this.endIntro.emit(true);
  }

  show(show: Boolean) {
    this.showIntro = show;
  }

  onClose() {
    this.endIntro.emit(true);
  }
  
}
