import { findLast } from '@angular/compiler/src/directive_resolver';
import { ElementRef } from '@angular/core';
import * as d3 from 'd3';
import { textwrap } from "d3-textwrap";

export class Tree {
    view: any;
    elementRef: ElementRef;
    treeGroup: any;
    treeGroupId: string = "tree-root-group";

    root: any;
    treeLayout: any;
    svg: any;
    centered: any;
    treeData: any;
  
    height: number;
    width: number;
    // margin: any = { top: 200, bottom: 90, left: 100, right: 90};
    margin: any = { top: 20, bottom: 40, left: 0, right: 0 };
    duration: number= 750;
    nodeWidth: number = 10;
    nodeHeight: number = 1;
    nodeRadius: number = 10;
    centerOffset: number = 0; //Used to ensure that the layout is kept in the center.
    centerX = 0;
    centerY = 0;
    horizontalSeparationBetweenNodes: number = 1;
    verticalSeparationBetweenNodes: number = 1;
    nodeTextDistanceY: number = -25; //-10;
    nodeTextDistanceX: number = -47; //-5;
    minTextLengthWrap: number = 23;
    nodeTapDetailsDistanceY: number = 5
    nodeHelperText: string = "Tap for Details";
    scaleOnSelected: number = 2.5;
    choosenClass = "choosen";
  
    dragStarted: boolean;
    draggingNode: any;
    nodes: any[];
    selectedNodeByDrag: any;
  
    selectedNodeByClick: any;
    previousClickedDomNode: any;

    //Zoom
    scaleMin = 0.5;
    scaleMax = 2.5;

    //Color schemes
    fontColor = "#ffffff";
    fontSize = "12px";
    innerCicleColor = "rgba(51,94,234,.61)"
    outerOpacity = "1";
  
    constructor(){
      this.view = this;
    }
  
    /**
     * 
     * @param chartContainer 
     */
    addSvgToContainer(chartContainer: ElementRef){
      this.elementRef = chartContainer;
      let element = this.elementRef.nativeElement;

      //Calculates the with and length;
      this.width = element.offsetWidth - this.margin.left - this.margin.right;
      this.height = element.offsetHeight - this.margin.top - this.margin.bottom;
  
      let svgExists = d3.select(element).selectAll("svg").size() > 0;
      if(!! svgExists) {
        return;
      }
  
      this.svg = d3.select(element).append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .append("g")
        .attr("id", this.treeGroupId)
        
      this.setZoomBehaviour();
    }

    getGroupDimensions(elm) {
      //DOM workaround doesn't work in d3 document.querySelector(`g#${this.treeGroupId}`).getBoundingClientRect();
      const dimensions = elm.selectAll(`g#${this.treeGroupId}`)
              ._parents[0]
              .getBoundingClientRect();
      
      return [dimensions.width, dimensions.height];
    }
  
    setZoomBehaviour() {
      this.centerX = (this.width + this.centerOffset) / 2;
      this.centerY = (this.height / 2);
      
      const svg = d3.select<SVGSVGElement, unknown>(`svg`);
      const [dimX, dimY] = this.getGroupDimensions(svg);
      const zoom = d3.zoom<SVGSVGElement, unknown>()
              //.scaleExtent([this.scaleMin, this.scaleMax])
              .on("zoom", zoomed);
      const self = this.view;
      self.cCords = [this.centerX, this.centerY];
      
      this.setMinMaxScale(zoom)
      zoom.translateTo(this.svg, dimX / 2, dimY / 2);
      
      svg.call(zoom)
        .call(zoom.duration, this.duration) //To handle zooming
        .on("dblclick.zoom", this.resetZoom)

      svg
        //Initialize set it 2x zoom
        .call(zoom.transform, 
                d3.zoomIdentity.translate(this.centerX, this.centerY)
                .scale(0.01)) 
        .transition()
        //Slowly zoom out
        .duration(this.duration)
        .call(zoom.transform, 
                d3.zoomIdentity.translate(this.centerX, this.centerY)
                .scale(1))

      //Zoom listener function
      function zoomed(){
        var g = d3.select(`g`);
        var transform = d3.event.transform; //self.panConstraint(g, d3.event.transform);
        g.attr("transform", transform);
      }
    }

    setMinMaxScale(zoom: d3.ZoomBehavior<Element, unknown>) {
      const minXScale = this.width / (2 * this.width);
      const minYScale = this.height / (2 * this.height);
      const minScale = Math.min(minXScale, minYScale);
      const maxScale = this.scaleMax;

      zoom.scaleExtent([minScale, maxScale]);
    }

    panConstraint(g, tr) {
      const gdim = this.getGroupDimensions(g);
      const maxWidth = this.width;
      const maxHeight = this.height;
      tr.x = Math.max(0, Math.min(tr.x, maxWidth));
      tr.y = Math.max(0, Math.min(tr.y, maxHeight));
      
      return tr
    }

    resetZoom(event) {
      const zoom = d3.zoom<SVGSVGElement, unknown>()
      const svg = d3.select<SVGSVGElement, unknown>(`svg`);
      svg
        .transition()
        .duration(this.duration)
        .call(zoom.transform, d3.zoomIdentity) 
    }
      
    //Create the tree while setting the tree to 
    createLayout(){
      this.treeLayout = d3.tree()
        .size([this.height, this.width])
        .nodeSize([this.nodeWidth + this.horizontalSeparationBetweenNodes, this.nodeHeight + this.verticalSeparationBetweenNodes])
        .separation((a,b)=>{return a.parent == b.parent ? 10 : 20});
    }
  
    createTreeData(treeData: any){
      this.root = d3.stratify<any>()
            .id(function(d) { return d.child; })
            .parentId(function(d) { return d.parent; })
            (treeData);
      this.root.x0 = 0;
      this.root.y0 = 20 + this.height / 2;
  
      this.root.children.map((d)=> this.expand(d));
    }
  
    collapse(d) {
      if(d.children) {
        d._children = d.children
        d._children.map((d)=>this.collapse(d));
        d.children = null
      }
    }
    expand(d) {
      if(d._children) {
        d.children = d._children
        d.children.map((d)=>this.expand(d));
        d.children = null
      }
    }
    expandAndFixHeight(d, newParent) {
      d.height= newParent.height-1;
      d.depth= newParent.depth+1;
  
      if(d._children){
        d.children= d._children;
        d._children= null;
      }
      if(d.children) {
        d.children.map((child)=>this.expandAndFixHeight(child, d));
      }
    }
    update(source) {
      const treeData = this.treeLayout(this.root);
      
      this.setNodes(source, treeData);
  
      this.setLinks(source, treeData);
  
    }
  
    setNodes(source:any, treeData: any){
      let nodes = treeData.descendants();
      let i = 0;
      let treeModel = this;
  
      //This helps to identify the depth
      nodes.forEach(function(d){ d.y = d.depth * 180 });
  
      //find all existing node tag or create a new node tag. 
      var node = this.svg.selectAll('g.node')
          .data(nodes, function(d) {return d.id || (d.id = ++this.i); });
  
      
      var nodeEnter = node.enter().append('g')
          .attr('class', 'node default-node')
          .attr("transform", function(d) {
            return "translate(" + d.x + "," + (180 - d.y) + ")";
          })
          .attr("id", function(d) {
            return d.data.id;
          });

      nodeEnter.append("circle")
        .attr('class', 'ghostCircle')
        .attr("r", function(d) {
          return treeModel.scaleNode(d, treeModel.nodeRadius, 1);
        })
        .attr('pointer-events', 'mouseover')
        .on("mouseover", function(node) {
            treeModel.overCircle(node);
            this.classList.add("over");
        })
        .on("mouseout", function(node) {
            treeModel.outCircle(node);
            this.classList.remove("over");
        });
  
      nodeEnter.append('circle')
          .attr('class', 'node')
          .attr('r', 1e-6);


      var wrap = textwrap().bounds({height: 100, width: 95});
      nodeEnter.append('text')
      .attr("dy", `${this.nodeTextDistanceY}px`)
      .attr("x", function(d) {
          return d.children || d._children ? -1 : 1;
      })
      .attr("text-anchor", function(d) {
        return "middle";  
        //return d.children || d._children ? "middle" : "start";
      })
      .text(function(d){
          return d.data.name || d.data.description || d.id;
      })
      .call(wrap);

      var nodeUpdate = nodeEnter.merge(node);
  
      nodeUpdate.transition()
        .duration(this.duration)
        .attr("transform", function(d) {
            return "translate(" + d.x + "," + (180 - d.y) + ")";
         });
  
      nodeUpdate.select('circle.node')
        .attr('r', function(d) {
          return treeModel.scaleNode(d, treeModel.nodeRadius, 0.9);
        })
        
      nodeUpdate.select("foreignObject")
        .attr("y", function(d) { //Do our best in ensuring the text fix.
          let yDist = d.data.name.length <= treeModel.minTextLengthWrap ? treeModel.nodeTextDistanceY : (treeModel.nodeTextDistanceY - 10);
          
          return `${yDist}px`;
        })
        .attr("x", `${this.nodeTextDistanceX}px`);
        
      // nodeUpdate.select("foreignObject div").append("span") //This is the Tap for Details description
      //   // .attr("dy", this.nodeTapDetailsDistanceY)
      //   // .attr("dx", "0px")
      //   // .attr("text-anchor", "middle")
      //   //.attr("visibility", "hidden")
      //   .attr("id", function(d) {
      //     return d.data.id + "-helper"; 
      //   })
      //   .attr("class", 'circle pulse')
      //   .attr("placement", "auto")
      //   .attr("popoverTitle", 'Left Nav')
      //   .attr('ngbPopover', '')
      //   .attr('popoverClass', 'cc-popover')
      //   .attr('animation', true);
        // .html("<app-path-intro [showIntro]=\"true\" [title]=\"'Left Nav'\" [message]=\"'Navigate to the left most option in the career path.'\" ></app-path-intro>");
        //.text(this.nodeHelperText);

      var nodeExit = node.exit().transition()
          .duration(this.duration)
          .attr("transform", function(d) {
              // return "translate(" + source.y + "," + source.x + ")";
              return "translate(" + d.x + "," + (180 - d.y) + ")";
          })
          .remove();
  
      // On exit reduce the node circles size to 0
      nodeExit.select('circle')
        .attr('r', 1e-6);
  
      // Store the old positions for transition.
      nodes.forEach(function(d){
        d.x0 = d.x;
        d.y0 = d.y;
      });
      // On exit reduce the opacity of text labels
      nodeExit.select('text')
        .style('fill-opacity', 1e-6);
  
      //Commented out this as dragging one element should drag the entire list.
      nodeEnter
        //.call(this.dragBehaviour())
        .on('click', function(d){
          treeModel.click(d, this);
          treeModel.update(d);
          d3.event.stopPropagation();
        });
    }

    scaleNode(data, nodeRadius, skew) {
        let level = data.data.level;
        let scale = nodeRadius / 2;
        
        // let scale = nodeRadius / (level + 1);
        return (nodeRadius * skew) * (scale);
    }
  
    dragBehaviour(){
      let treeModel= this;
      function subject(d) {
          return { x: d3.event.x, y: d3.event.y }
      };
      function dragStart(d){
        treeModel.draggingNode= d;
        d3.select(this).classed("active", true);
  
        d3.select(this).select('.ghostCircle').attr('pointer-events', 'none');
        d3.selectAll('.ghostCircle').attr('class', 'ghostCircle show');
  
        treeModel.nodes= d.descendants();
        treeModel.dragStarted= true;
  
      }
  
      function dragged(d){
        d3.select(this)
          .attr("transform", "translate(" + d3.event.x + "," + d3.event.y + ")");
  
        if(treeModel.dragStarted){
          treeModel.svg.selectAll("g.node").sort((a, b) => { // select the parent and sort the path's
              if (a.id != treeModel.draggingNode.id) return 1; // a is not the hovered element, send "a" to the back
              else return -1; // a is the hovered element, bring "a" to the front
          });
  
          // if nodes has children, remove the links and nodes
          const childs= d.descendants();
          if (childs.length > 1) {
              // remove link paths
              let links = d.links();
              treeModel.svg.selectAll('path.link').filter(function(d, i) {
                    if (d.id == treeModel.draggingNode.id) {
                        return true;
                    }
                    return false;
                }).remove();
  
              // remove child nodes
              let nodesExit = treeModel.svg.selectAll("g.node")
                  .data(treeModel.nodes, function(d) {
                      return d.id;
                  }).filter(function(d, i) {
                      if (d.id == treeModel.draggingNode.id) {
                          return false;
                      }
                      return true;
                  }).remove();
          }
  
          // remove parent link
          const parentLink = d.links(d.parent.descendants());
          treeModel.svg.selectAll('path.link').filter(function(d, i) {
              if (d.id == treeModel.draggingNode.id) {
                  return true;
              }
              return false;
          }).remove();
  
          treeModel.dragStarted = false;
        }
  
      }
  
      function dragEnd(d){
        d3.select(this).classed("active", false);
  
        d3.selectAll('.ghostCircle').attr('class', 'ghostCircle');
        d3.select(this).attr('class', 'node');
  
        if (d == treeModel.root) {
            return;
        }
        let domNode = this;
        if (treeModel.selectedNodeByDrag) {
            // now remove the element from the parent, and insert it into the new elements children
            var index = treeModel.draggingNode.parent.children.indexOf(treeModel.draggingNode);
            if (index > -1) {
                treeModel.draggingNode.parent.children.splice(index, 1);
            }
            if (treeModel.selectedNodeByDrag.children != null || treeModel.selectedNodeByDrag._children != null ) {
                if (treeModel.selectedNodeByDrag.children != null ) {
                    treeModel.selectedNodeByDrag.children.push(treeModel.draggingNode);
                } else {
                    treeModel.selectedNodeByDrag._children.push(treeModel.draggingNode);
                }
            } else {
                treeModel.selectedNodeByDrag.children = [treeModel.draggingNode];
            }
            //set new parent
            treeModel.draggingNode.parent= treeModel.selectedNodeByDrag;
            // Make sure that the node being added to is expanded so user can see added node is correctly moved
            treeModel.expandAndFixHeight(treeModel.draggingNode, treeModel.selectedNodeByDrag);
            //sortTree();
            treeModel.nodechanged(treeModel.draggingNode);
            endDrag(domNode);
        } else {
            endDrag(domNode);
        }
      }
  
      function endDrag(domNode) {
          d3.selectAll('.ghostCircle').attr('class', 'ghostCircle');
          d3.select(domNode).attr('class', 'node');
          // now restore the mouseover event or we won't be able to drag a 2nd time
          d3.select(domNode).select('.ghostCircle').attr('pointer-events', '');
  
          if (treeModel.draggingNode !== null) {
            treeModel.update(treeModel.root);
            //centerNode(treeModel.draggingNode);
            treeModel.draggingNode = null;
          }
  
          treeModel.selectedNodeByDrag = null;
      }
  
      return d3.drag()
              .subject(subject)
              .on("start", dragStart)
              .on("drag", dragged)
              .on("end", dragEnd);
    }
  
    overCircle(d) {
        this.selectedNodeByDrag = d;
    };
    outCircle(d) {
        this.selectedNodeByDrag = null;
    };
  
    setLinks( source: any, treeData: any){
      let links = treeData.descendants().slice(1);
      var link = this.svg.selectAll('path.link')
          .data(links, function(d) { return d.id; });
  
      // Enter any new links at the parent's previous position.
      var linkEnter = link.enter().insert('path', "g")
          .attr("class", "link")
          .attr('d', (d) =>{
            return this.diagonalCurvedPath(d.parent, d)
          });
  
      var linkUpdate = linkEnter.merge(link);
  
      linkUpdate.transition()
          .duration(this.duration)
          .attr('d', (d)=>{
            return this.diagonalCurvedPath(d.parent, d)});
  
      var linkExit = link.exit().transition()
          .duration(this.duration)
          .attr('d', (d) => {
            var o = {x: source.x, y: (source.y)}
            return this.diagonalCurvedPath(o, o)
          })
          .remove();
    }
  
    click(d, domNode) {
      var self = this;
      if(this.previousClickedDomNode) {
        this.previousClickedDomNode.classList.remove("selected");
      // if (d.children) {
      //     d._children = d.children;
      //     d.children = null;
  
          //domNode.classList.remove("selected");
      } else {
      //   d.children = d._children;
      //   d._children = null;
  
        domNode.classList.add("selected");
      }

      this.selectedNodeByClick= d;
      this.previousClickedDomNode = domNode;
      this.centerNode(d, domNode).then(function(done) {
        self.nodeselected(d); //Fire the event to display the steps once it finish being at the center.
      });
    }
  
    // Creates a curved (diagonal) path from parent to the child nodes
    diagonalCurvedPath(s, d) {
      
      const path = `M ${s.y} ${s.x}
              C ${(s.y + d.y) / 2} ${s.x},
                ${(s.y + d.y) / 2} ${d.x},
                ${d.y} ${d.x}`;
  
      const customPath = `M ${s.x0}, ${180 - s.y0}
                L ${d.x}, ${180 - d.y}`;

      // d3.path().bezierCurveTo()
      return customPath;
    }
  
    radialPoint(x, y) {
      return [(y = +y) * Math.cos(x -= Math.PI / 2), y * Math.sin(x)];
    }
  
    addNode(newNode: any){
      if(this.selectedNodeByClick){
        if(this.selectedNodeByClick.children)
          this.selectedNodeByClick.children.push(newNode);
        else if(this.selectedNodeByClick._children)
          this.selectedNodeByClick._children.push(newNode);
        else
          this.selectedNodeByClick.children= [newNode];
        this.update(this.selectedNodeByClick);
      }else{
        this.root.children.push(newNode);
        this.update(this.root);
      }
    }
  
    //events
    nodechanged(node){}

    nodeselected(node){}

    triggerClickEvent(datum) {
      let self = this;
      this.treeData = datum;
      var selection = d3.select<HTMLElement, unknown>(`#${datum.id}`);
      var gnode = selection.node();

      this.click(datum, gnode);
      
      //Highlight all sections
      var find = this.flatten(this.root).find(function(d) {
        if(d.data.id == datum?.id) {
          return true;
        }
      });

      this.resetPaths();
      gnode.classList.add(self.choosenClass); //Set current as highlighted
      while(find.parent) {
        let gparent = d3.select<HTMLElement, unknown>(`#${find.parent.data.id}`).node();
        gparent.classList.add(self.choosenClass);
        find = find.parent;
      }

      this.update(datum);
    }

    /**
     * Navigate the selected node to the center.
     * @param data 
     * @param domNode 
     * @returns 
     */
    centerNode(data: any, domNode: any): Promise<any> {
      var group = d3.select(`g#${this.treeGroupId}`).attr("transform"),
          groupCords = {},
          selectedCords = {};

      
      groupCords = this.getTransformValues(group);
      selectedCords = d3.select(domNode).attr("transform")
      selectedCords = this.getTransformValues(selectedCords);
      
      if(!data || this.centered === data) {
        console.warn("centerNode: Clearing the center as the current element is in the center: ", data, this.centered)
        this.centered = null;
      } else {
        var svgHeight = this.height,//Number(d3.select("svg").attr("height")),
            svgWidth = this.width;//Number(d3.select("svg").attr("width"));
        
        this.centerX = (svgWidth / 2);
        this.centerY = (svgHeight / 2);        
      }
      
      var self = this;
      return new Promise(function(resolve, reject) {
      
        self.svg.transition()
          .duration(self.duration)
          .attr("transform", "translate(" + (self.centerX - selectedCords['x']) + ", " + (self.centerY - selectedCords['y']) + ")")
          .on("end", function(d) {
            var snode = d3.select(domNode);
            var helperText = d3.select(`#${data.id}-helper`)
            var transform = snode.attr("transform");
            var scale =` scale(${self.scaleOnSelected})`;
            
            //Only add the scale option if it doesn't exists
            if(snode.attr("transform").indexOf("scale(") < 0) {
              snode.attr("transform", `${transform} ${scale}`)
                .attr("opacity", 1);
            }
            
            setTimeout(function() {
              resolve(true); //Notify outside world that it finishes centering the element.
            }, 90)
          });
      });
        
    }

    /**
     * Extract the transformation values.
     * @param element 
     * @returns 
     */
    getTransformValues(element) {       
      var values = element.split(")");
      for (var key in values){
          var val = values[key];              
          var prop = val.split("(");          
          if (prop[0].trim() != "") {
            var coords = prop[1].split(",");
            return {
              x: parseFloat(coords[0]), 
              y: parseFloat(coords[1]) 
            }
          }
      }                   
      return false;           
    }

    /**
     * An algorithm used to flatten the tree and then use that data to track path travelled.
     * @param root 
     * @returns 
     */
    flatten(root) {
      var nodes = [];
      var i = 0;

      function recurse(node) {

        if(node.children) node.children.forEach(recurse);
  
        if(node._children) node._children.forEach(recurse)
        
        if(!node.id) {

        }
        nodes.push(node);
      }      
      recurse(root); //Start the recursive
      return nodes;
    }


    /**
     * This method undo all the highlighted paths selected.
     */
    resetPaths() {
      let self = this;
      this.flatten(this.root).forEach(function(d) {
        //Remove the style on all
        let el = d3.select<HTMLElement, unknown>(`#${d.data.id}`).node();
        el.classList.remove(self.choosenClass);
        
      });
      this.update(this.root);      
    }

}