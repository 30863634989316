/**
 * @description The purpose of this model is to track who influenced or motivate an individual to choose a career path.
 */
export class Motivator {
    title: String;
    reason: String;
    choosen: Boolean;


    constructor(title: string, reason?: String, choosen?: Boolean) {
       this.title = title;
       this.reason = (!! reason? reason : '');
       this.choosen = !! choosen; 
    }

}