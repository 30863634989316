export class ModalConfig {
    animation?: Boolean;
    ariaLabelledBy?: string;
    container?: string | HTMLElement;
    backdropClass?: string; 
    modalDialogClass?: string;

    constructor(container?, animation?, arialLabelledBy?) {
        if(!! container) {
            this.container = container;
        }
        if(!! arialLabelledBy) {
            this.ariaLabelledBy = arialLabelledBy;
        }

        this.backdropClass = "cc-modal-dialog";
        this.modalDialogClass = "mt-md-5";
        this.animation = !! animation;
    }
}