<div *ngIf="visible" class="container step-details-backdrop pr-0 pl-0">
    <div class="flex-item step-details-content">
        <div *ngIf="! previewMode">
            <button (click)="close()" class="close-icon close" aria-label="Close">
                <span aria-hidden="true">⊹</span>
            </button>  
        </div>
        <ngb-carousel #stepWizard>
            <ng-template *ngFor="let stepDetail of steps; let index = index" ngbSlide>
                
                <div class="container full-width">
                    <!-- <div class="row"> -->
                        <app-step-template
                            class="row"
                            [type]="stepDetail?.type" 
                            [heading]="stepDetail?.title" 
                            [jobTitle]="jobTitle"
                            [previewMode]="previewMode"
                            [splashImage]="stepDetail?.image"
                            [detailExplanation]="stepDetail?.description"
                            [detailItems]="stepDetail?.list"
                            [toolTipText]="stepDetail?.objective"
                            [supplimentals]="stepDetail?.supplimentals"
                            [summaryTooltipText]="stepDetails?.summary">
                        </app-step-template>
                    <!-- </div> -->                    
                </div>
            </ng-template>
        </ngb-carousel>
        <div class="row step-nav">
            <button class="col col-sm-4 btn btn-secondary {{slideNumber==1?'disabled':''}}" 
                (click)="previous()">Previous</button>    
            <div class="col col-sm-4">
                &nbsp;
            </div>
    
            <button *ngIf="! previewMode && finalPage == true" 
                class="col col-sm-4 btn btn-primary"
                (click)="complete()">Done</button>
    
            <button class="col col-sm-4 btn btn-{{finalPage==true?'secondary':'primary'}}" *ngIf="finalPage != true"
                (click)="next()">Next</button>
        </div>
    </div>
</div>