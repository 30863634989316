import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfig } from 'src/app/d3/models/modalConfig';
import { Step } from 'src/app/d3/models/step';
import { StepService } from 'src/app/d3/services/step.service';
import { StepDetailsComponent } from '../step-details/step-details.component';
import APP_CONFIG from 'src/app/app.config';
import { ComponentService } from 'src/app/services/component/component.service';
import { PathService } from 'src/app/d3/services/path.service';
import { Decision } from 'src/app/d3/models/decision';
import { Summary } from 'src/app/d3/models/summary';
import { AnalyticsService } from 'src/app/services/tracking/analytics.service';
import { PathIntroComponent } from '../path-intro/path-intro.component';
import { PathNavigationComponent } from '../path-navigation/path-navigation.component';

@Component({
  selector: 'app-path',
  templateUrl: './path.component.html',
  styleUrls: ['./path.component.scss']
})
export class PathComponent implements OnInit {
  private readonly paths = APP_CONFIG.APPLICATION.URL;
  private readonly decisionPath = this.paths.DECISION;
  private summaryPath: String = APP_CONFIG.APPLICATION.PATHS.SUMMARY;
  private screenName = APP_CONFIG.APPLICATION.ANALYTICS.SCREENS.PATHWAY;
  private actions = APP_CONFIG.APPLICATION.ANALYTICS.ACTIONS;

  traversed: Object[] = []; //List of data path traversed.
  decisions: Decision[];
  level: String;
  mainVisible: Boolean = false;
  data: Object[] = [];
  activeNode: any;
  activeModalsRef : NgbModalRef[] = [];
  modalContainerSelector = APP_CONFIG.APPLICATION.ADDITIONAL.MODAL_SELECTION_PARENT;
  mConfig : ModalConfig = new ModalConfig();
  
  showIntro: Boolean;
  showNav: Boolean;
  leftPath: any;
  additionalPaths: any[];
  
  

  @ViewChild("pathNavigationInsert", { read: ViewContainerRef })
  pathNavigationInsert : ViewContainerRef;

  constructor(private router: Router,
    private stepService : StepService,
    private modalService: NgbModal,
    private modalConfig : NgbModalConfig,
    private pathService: PathService,
    private analytics: AnalyticsService,
    private componentService: ComponentService) { 
    
    //Set the modal
    this.componentService.setModalConfig(this.modalConfig, this.modalContainerSelector);
    let self = this;
    this.modalService.activeInstances.subscribe( (list) => {
      self.activeModalsRef.push(...list);
    });
  }

  ngOnInit(): void {
    let statData = history.state;
    let decisions = statData?.decisions;
    let treeMap = statData?.treeMap;
    let initTimeout = 100;

    //If no decision or tree data was found, redirect to the decision step:
    if(! decisions || decisions.length < 1 || ! treeMap) {
      this.router.navigate([this.decisionPath]);
      return;
    }

    this.setData(treeMap, decisions);
    let self = this;
    setTimeout( () => {
      if(this.canOpenIntro()) {
        self.openIntro();
      } else {
        self.injectPathNavigationComponent(self.data);
      }
    }, initTimeout);

    this.analytics.setCurrentScreen(this.screenName);
  }

  setData(values, decisions?: Decision[], level?: String) {
    this.mainVisible = true;
    this.data = values;
    this.decisions = decisions;
    this.level = level;
  }
  
  /**=================
  * Events
  **/
  nodeSelected(node: any) {
    console.log("app detected node selected", node);
    this.activeNode = node; //Setting the active node which should be focused on.

    //TODO: Animate the activeNote before displaying it
    this.onNodeFocus();

    
    if(!! node && !!node.data) {
      this.stepService.buildStepDetails(node);
      const steps = this.stepService.getStepDetails();
      
      this.openStepDetails(steps);  //Initiate the steps.
      
      let eventName = this.analytics.SELECT_CONTENT;
      let eventDetails = this.analytics.buildPageSelectContentParams(`${this.actions.STEP}-${this.actions.OPEN}`, node.child);
      this.analytics.logEvent(eventName, eventDetails);
    }
  }

  nodeUpdated(node: any) {
    console.log("app detected node change: ", node);
  }

  onNodeFocus(): void {
    //this set the active node to focus
    console.log('Setting focus to: ', this.activeNode);
  }

  openStepDetails(steps: Step[]) {
    let ref = this.modalService.open(StepDetailsComponent, this.modalConfig)
    let instance : StepDetailsComponent = ref.componentInstance;
    let options = {
      name: this.activeNode?.data?.name
    };

    instance.showNode(steps, null, options);
    
    this.activeModalsRef.push(ref);
    
    //For each modals being tracked, find the appropriate one and call the respective method.
    this.activeModalsRef.forEach( (ref : NgbModalRef) => {
      const component = ref.componentInstance;
      if( component instanceof StepDetailsComponent) {
        let self = this;
        component.onStepsComplete.subscribe(() => {
          self.closeModal();
        });
      }
    });

  }

  closeModal() {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
  }

  /**
   * Close the introduction
   */
  closePathIntro() {
    this.showIntro = false;
    this.closeModal();
    this.injectPathNavigationComponent(this.data);
  }

  /**
   * Will be used to determine who sees the intro.
   * @returns 
   */
  canOpenIntro(): Boolean {
    return false;
  }

  openIntro() {
    let ref = this.modalService.open(PathIntroComponent, this.modalConfig)
    let instance : PathIntroComponent = ref.componentInstance;

    instance.show(true);
    
    this.activeModalsRef.push(ref);
    
    //For each modals being tracked, find the appropriate one and call the respective method.
    // this.activeModalsRef.forEach( (ref : NgbModalRef) => {
    //   const component = ref.componentInstance;
    //   if( component instanceof PathIntroComponent) {
    //     let self = this;
    //     component.onIntroComplete.subscribe(() => {
    //       instance.enable(false); //Disable the dom.
    //       self.closePathIntro();
    //     });

    //     //If user skip the intro
    //     component.onSkipIntro.subscribe(() => {
    //       instance.enable(false);
    //       self.closePathIntro();
    //     });
    //   }
    // })

  }

  injectPathNavigationComponent(results: any) {
    //Send initial data to child component
    this.showNav = true;
  }

  onNextRungHandler(rungData: any) {
    console.log(`Emitted from path navigation: `, rungData);
  
    if(! rungData.isStart ) {
      this.activeNode = rungData.data;
    } 
    if(rungData[PathNavigationComponent.MODE_PARAM] == PathNavigationComponent.MODE_RESTART) {
      this.activeNode = rungData.data;
      this.traversed = []; //Reset the list of travelled paths
      this.nodeSelected(this.activeNode);
    }

    this.traversed.push(rungData.data);
    
    //Animate to the center.
    this.onNodeFocus();
  }

  onCompleteHandler(data: any) {
    console.log(`The path completed: `, data); 
    this.mainVisible = false;  
    let uniqueSummaryId = this.saveDetails(this.traversed, this.decisions) || null; //Instantly saves the data then sends the key over to the next page 
    
    let extras: NavigationExtras = { 
      state: {
        data: {}
      },
      preserveFragment: true
    };
    

    //Navigate to summar page.
    this.router.navigate([this.paths.SUMMARY, uniqueSummaryId], extras);
  }

  /**
   * The callback used to store the details before passing it to the summary page
   * @param traversed 
   * @param decisions 
   * @returns 
   */
  saveDetails(traversed, decisions: Decision[]): String {
    let summary = new Summary(true, decisions, traversed);
    return this.pathService.push(this.summaryPath, summary);
  }
}
