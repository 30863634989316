<div class="step-template" *ngIf="!type || type != advType">
    <div *ngIf="! previewMode" 
        class="slash-section mx-auto">
        <img class="splash-image" [src]="fullSplashPath" [alt]="splashImageAlt" />
    </div>

    <div class="modal-header">
        <span *ngIf="jobTitle" class="section-title" ngbTooltip="{{summaryTooltipText}}">{{jobTitle}}</span>
        <h4 class="modal-title">{{heading}}
            <span *ngIf="!previewMode && toolTipText != ''"
                class="cc-tooltip" 
                ngbTooltip="{{toolTipText}}" [autoClose]="true" triggers="click">&nbsp;</span>
            </h4>
    </div>

    <div *ngIf="! previewMode && supplimentals?.length > 0"
        class="section-sponsors row mb-3 mt-3" >
        <div class="sponsor col pl-0 pr-0" 
            *ngFor="let sponsor of supplimentals; let index = index">
            
            <a class="cc-{{sponsor.type}}"
                target="_blank" 
                href="{{sponsor.url}}" 
                alt="{{sponsor.message}}">
                
                <span class="sponsor-icon pr-3">★</span>
                {{sponsor.message}}
            </a>
        </div>
    </div>

    <div class="content splash-list-section cp-top-border" 
        [ngClass]="previewMode ? 'preview-mode': ''">
        <p>{{detailExplanation}}</p>

        <ul class="splash-list">
            <li *ngFor="let item of detailItems" class="pb-3">
                <i class="fas fa-square pr-3">⊚</i>
                {{item.subject}}
            </li>
        </ul>
    </div>
    
</div>
<div class="step-template adv-template" *ngIf="type == advType">
    <a href="">
        <img class="adv-image" [src]="fullSplashPath" [alt]="splashImageAlt" />
    </a>
</div>