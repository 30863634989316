<div *ngIf="visible" class="container feedback-backdrop">
    <div class="flex-item feedback-content">
        <div>
            <button (click)="maybeLater()" class="close-icon close" aria-label="Close">
                <span aria-hidden="true">⊹</span>
            </button>  
        </div>
        <div class="submitted-feedback" *ngIf="submitted==true && !feedbackForm?.invalid">
                
            <img class="thanks-icon" src="../../../assets/images/icons/thanks.png" loading="lazy"/>
            <p class="text-{{(feedbackForm.invalid)?'danger': 'success'}} text-center" 
                *ngIf="submitted==true">Thank you for your feedback!</p>

            
                <button  
                        class="col col-sm-5 btn btn-success" 
                        (click)="close()">Ok</button>

        </div>
        <!-- The form details -->
        <form [formGroup]="feedbackForm" 
            #ngForm="ngForm" 
            (ngSubmit)="submitFeedback()"
            class="card-body"
            *ngIf="!submitted || (submitted==true && feedbackForm?.invalid)">

        
                <p class="text-{{(feedbackForm.invalid)?'danger': 'success'}} text-center" 
                    *ngIf="submitted==true">{{feedbackForm.invalid? "Fail to process due to error." :"Than you for your feedback."}}</p>    
                <!-- <div > -->
                <ul class="rate-area">
                    <input type="radio" id="1-star" name="rating" formControlName="rating" value="1">
                    <label for="1-star" title="Amazing">1 stars</label>
                    
                    <input type="radio" id="2-star" name="rating" formControlName="rating" value="2">
                    <label for="2-star" title="Good">2 stars</label>
                    
                    <input type="radio" id="3-star" name="rating" formControlName="rating" value="3">
                    <label for="3-star" title="Average">3 stars</label>
                    
                    <input type="radio" id="4-star" name="rating" formControlName="rating" value="4">
                    <label for="4-star" title="Not Good">4 stars</label>
                    
                    <input type="radio" id="5-star" required="" name="rating" formControlName="rating" value="5" aria-required="true">
                    <label for="5-star" title="Bad">5 star</label>
                </ul>
                    
                <textarea rows="10" 
                    id="message"
                    maxlength="{{maxlength}}" 
                    formControlName="message"
                    placeholder="Please, go ahead and share your honest feedback."></textarea>
                
                <div class="row step-nav">
                
                    <button  
                        class="col col-sm-5 btn btn-secondary" (click)="maybeLater()">Maybe later</button>
                    
                    <div class="col col-sm-2">&nbsp;</div>

                    <button type="submit" 
                        class="col col-sm-5  btn btn-primary">Feedback</button>
                </div>
        </form>
    </div>
</div>