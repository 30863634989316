import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { WelcomeComponent } from './visuals/welcome/welcome.component';
import { NotFoundComponent } from './visuals/not-found/not-found.component';
import { AuthComponent } from './visuals/auth/auth.component';
import { TermsPolicyComponent } from './visuals/tnc/terms-policy/terms-policy.component';
import { DecisionWizardComponent } from './visuals/decision-wizard/decision-wizard.component';
import { PathSummaryComponent } from './visuals/path-summary/path-summary.component';
import { DecisionResolver } from './services/resolver/decision-resolver';
import { PathComponent } from './visuals/path/path.component';
import { CookiePolicyComponent } from './visuals/cookie-policy/cookie-policy.component';
import { GoodbyeComponent } from './visuals/goodbye/goodbye.component';

const routes: Routes = [
  { 
    path: 'welcome', 
    component: WelcomeComponent,
  },
  {
    path: 'start',
    component: DecisionWizardComponent,
    canActivate: [AuthGuardService],
    resolve: { decisions: DecisionResolver } //The resolve pulls data before loading the page.
  },
  {
    path: 'path',
    component: PathComponent,
    canActivate: [AuthGuardService]
    //resolve: { data: PathResolver } //Pulls the node data to be displayed on the svg.
  },
  {
    path: 'summary/:sid',
    component: PathSummaryComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'good-bye',
    component: GoodbyeComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'auth',
    component: AuthComponent
  },
  {
    path: 'terms-policy',
    component: TermsPolicyComponent
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent
  },
  {
    path: '',
    redirectTo: '/welcome',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
