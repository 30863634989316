<!-- The main path -->
<d3-reverse-tree class="container-fluid d3-main"
        *ngIf="mainVisible"
        [(treeData)]="data"
        [(activeNode)]="activeNode"
        (onNodeChanged)="nodeUpdated($event)" 
        (onNodeSelected)="nodeSelected($event)"></d3-reverse-tree>


<!-- Step 2: Path Navigator Helper/Navigator -->
<!-- <ng-template #pathNavigationInsert></ng-template>  -->
<app-path-navigation
        [(showNav)]="showNav"
        [data]="data"
        [leftPath]="leftPath"
        [additionalPaths]="additionalPaths"
        (onNextRung)="onNextRungHandler($event)"
        (onComplete)="onCompleteHandler($event)">
</app-path-navigation>