/**
 * This is used for the introduction wizard
 */

import { Decision } from "./decision";

export class Summary {
   enable: Boolean;
   decisions: Decision[] = [];
   traversed: Object;
   date: Date;

   constructor(enable, decisions: Decision[], traversed) {
    this.enable = enable;
    this.decisions = decisions;
    this.traversed = traversed;
    this.date = new Date();
   }
}