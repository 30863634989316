

//Description: https://ng-bootstrap.github.io/#/components/carousel/api
export class CarouselConfig {
    animation?: Boolean;
    keyboard?: Boolean;
    showNavigationArrows?: Boolean;
    showNavigationIndicators?: Boolean;
    wrap?: Boolean;
    focused?: Boolean;
    pauseOnFocus?: Boolean;
    pause?: Boolean;

    constructor(wrap, animation?, keyboard?, showNavigationArrows?, showNavigationIndicators?) {
        this.animation = !! animation;
        this.wrap = !!wrap;
        this.keyboard = !!keyboard;
        this.showNavigationArrows = !! showNavigationArrows;
        this.showNavigationIndicators = !!showNavigationIndicators;
        this.focused = true; //Automatically set carousle to be in focus once it is created.
        this.pauseOnFocus = true; //This will prevent the slide process from starting too fast.
        this.pause = true;
    }

}