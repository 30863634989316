import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { title } from 'process';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  public readonly LOGIN = "login";
  public readonly PAGE_VIEW = "page_view";
  public readonly SELECT_CONTENT = "select_content";
  

  private LoginTemplate = {
    method: ""
  };
  private PageViewTemplate = {
    page_location: "",
    page_title: "",
    page_encoding: "UTF-8",
    user_agent: "",
    client_id: "",
    language: "en_us"
  };
  private SelectContentTemplate = {
    content_type: "",
    item_id: ""
  }

  private template: Object = {};
  private templateLogin: Object = {};

  
  constructor(private analytics : AngularFireAnalytics) { 
    this.template[this.LOGIN] = this.LoginTemplate;
    this.template[this.PAGE_VIEW] = this.PageViewTemplate;
    this.template[this.SELECT_CONTENT] = this.SelectContentTemplate; 
  }

  setCurrentScreen(name: string) {
    this.analytics.setCurrentScreen(name);
  }

  logEvent(eventName, params: Object) {
    this.analytics.logEvent(eventName, params);
  }

  getTemplate(type: string): Object {
    return this.template[type];
  }

  buildPageViewParams(url, title?, user_agent?, language?, client_id?, encoding?){
    let pgTmpl = this.PageViewTemplate;
    pgTmpl.page_location = url;
    pgTmpl.page_title = title || pgTmpl.page_title;
    pgTmpl.page_encoding = encoding || pgTmpl.page_encoding;
    pgTmpl.user_agent = user_agent || pgTmpl.user_agent;
    pgTmpl.client_id = client_id || pgTmpl.client_id;
    pgTmpl.language = language || pgTmpl.language;

    return pgTmpl
  }

  buildPageSelectContentParams(content_type, item_id) {
    let scTmpl = this.SelectContentTemplate;
    scTmpl.content_type = content_type;
    scTmpl.item_id = item_id;

    return scTmpl;
  }


}