import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import CONFIG from 'src/app/app.config';
import { AuthenticateService } from '../authenticate/authenticate.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  private readonly redirectToParam = CONFIG.APPLICATION.URL.PARAMS.REDIRECT_TO; 
  private readonly urlPaths = CONFIG.APPLICATION.URL;

  constructor(public authService : AuthenticateService, public router: Router) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if(! this.authService.isAuth()) {
      //this.router.navigate(["/auth"]);
      let path = this.urlPaths.AUTH;
      if(!! route.url && route.url.length > 0) {
        let redirectTo = `${this.redirectToParam}=/${route.url.join('/')}`;
        path = encodeURI(`${path}?${redirectTo}`);
      }

      window.location.href = path;
      return false;
    }

    console.log("Can Activate")
    return true;
  }
  
}
