import { Component, OnInit } from '@angular/core';
import CONFIG from 'src/app/app.config';
import {CookieService} from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss'],
  host: {
    'class': 'container cc-component-cookie',
    'id': 'cc-cookie'
  }
})
export class CookieComponent implements OnInit {

  private cookie_name = CONFIG.APPLICATION.ADDITIONAL.COOKIE_NAME;
  cookie_policy_url = CONFIG.APPLICATION.URL.COOKIE_POLICY;
  showNotice: Boolean = true;
  defaultOption = {
    allowTracking: true 
  };  

  constructor(private cookieService: CookieService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.showNotice = this.canShowNotice();
  }

  canShowNotice(): Boolean {
    return (! this.cookieService.get(this.cookie_name));
  }

  setCookie(value) {
    let cdata = this.defaultOption;

    if(typeof value == "object") {
      cdata = value;
    } else {
      cdata.allowTracking = value;
    }
    
    this.cookieService.set(this.cookie_name, JSON.stringify(cdata), this.getExpirationTime());
  }

  getExpirationTime() {
    let d = new Date();
    
    return d.setMonth(d.getMonth() + 1); 
  }

  close() {
    this.showNotice = false;
    this.setCookie(true);
  }
}
