import { Injectable } from '@angular/core';
import { AdvertisementService } from 'src/app/services/advertisement/advertisement.service';
import { Step } from '../models/step';
import { StepListItem } from '../models/stepListItem';
import { Supplimental } from '../models/supplimental';

@Injectable({
  providedIn: 'root'
})
export class StepService {
  stepsDetails: Step[] = [];
  // node: any = {};

  constructor(private advService: AdvertisementService) {
    this.advService.getAdvs(); // setup the advs needed by the application.
  }

  getStepDetails(): Step[] {
    if(!!this.stepsDetails || this.stepsDetails.length < 1) {
      console.warn("No step details returning ")
    }
    return this.stepsDetails
  }

  buildStepDetails(node: any) {
    let data = node.data;
    let stepModel: Step[] = [];
    
    if(!! data && !!data.steps) {

      data.steps.forEach( (step) => {
        //1. Build the list item
        let listItems = this.buildStepListItem(step.list)

        let supplimentals = [];
        if(!! step.supplimentals) {
          supplimentals = this.buildSupplementals(step.supplimentals);
        }

        let summary = data?.summary;
        //2. add items to each step
        stepModel.push(this.buildStep(step, listItems, supplimentals, summary));
      })
    }

    //TODO: Add back this feature
    //Manage and inject advertisement
    //this.advService.injectAdvs(stepModel);

    if (stepModel != null && stepModel.length > 0) {
      this.stepsDetails = stepModel;
      //this.changeDetection.detectChanges();  
    }

  }

  private buildStepListItem(listItems: any[]) {
    //new StepListItem('Business Principles', 'Understand business principles', '', '', true),
    let list: StepListItem[] = [];

    listItems.forEach( (listItem) => {
      let temp = new StepListItem(listItem.name, listItem.subject, listItem.description, '', listItem.enable);

      list.push(temp);
    });
    return list;
  }

  private buildSupplementals(items: any[]) {
    let supplimentals: Supplimental[] = [];
    
    items.forEach( (sup) => {
      
      if(!! sup.enable) {
        let temp = new Supplimental(sup.enable, sup.type, sup.message, sup.url);
        supplimentals.push(temp);
      }
    });
    return supplimentals;
  }

  private buildStep(step: any, list: StepListItem[], supplimentals: Supplimental[],  summary?: string) {
    return new Step(step.type, step.title, step.image, step.description, step.objective, list, supplimentals, summary);
  }
}
