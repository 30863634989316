<div class="bye mx-auto text-center mt-5 mb-5">
    <!-- The top -->
    <div class="cc-top">
        <div class="text-center">
            <img src="../../../../assets/splash/warning.png" alt="Proceed with caution" loading="lazy"/>
        </div>
        <h2 class="mt-5">Saying Goodbye?</h2>
        <p *ngIf="isComplete != true">Hi {{!!user.displayName? user.displayName: 'There'}}! Seems like you are interested in deleting your data. Please, once you confirm your action below, we won't be able to undo your request.</p>
        
    </div>

    <!-- The content -->
    <div class="cc-content" *ngIf="isComplete !== true">
        <!-- If not logged in -->
        <div class="col mx-auto text-center">
            <a class="btn btn-primary mt-3" [routerLink]="['/']">Home</a>
        </div>
        <div class="col mx-auto text-center">
            <a class="btn btn-danger mt-3" (click)="deleteUser()">Remove My Data</a>
        </div>
    </div>

    <!-- The Complete deletion -->
    <div class="cc-content" *ngIf="isComplete === true">
        <!-- If not logged in -->
        <p>We've successfully deleted your information. We welcome your feedback and look forward to seeing you again. Thanks.</p>
        <div class="col mx-auto text-center">
            <a class="btn btn-primary mt-3" [routerLink]="['/']">Home</a>
        </div>
    </div>
</div>
