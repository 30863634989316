import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Choice } from 'src/app/d3/models/choice';
import { PathService } from 'src/app/d3/services/path.service';
import { Decision } from 'src/app/d3/models/decision';
import { NgbCarousel, NgbCarouselConfig, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { CarouselConfig } from 'src/app/d3/models/carouselConfig';
import APP_CONFIG from 'src/app/app.config';
import { DecisionService } from 'src/app/d3/services/decision.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AnalyticsService } from 'src/app/services/tracking/analytics.service';
import { ComponentService } from 'src/app/services/component/component.service';
@Component({
  selector: 'app-decision-wizard',
  templateUrl: './decision-wizard.component.html',
  styleUrls: ['./decision-wizard.component.scss'],
  host: {
    'class':  'container cc-component-container my-5 py-md-5 py-lg-5 py-sm-0', 
    'id': 'decision-backdrop'
  },
  providers: [NgbCarouselConfig, NgbDropdownToggle] //Add carousel config to allow for config settings
})
export class DecisionWizardComponent implements OnInit {
  private readonly appConfig = APP_CONFIG.APPLICATION;
  private readonly rootPath = this.appConfig.PATHS.ROOT;
  private readonly pathUrl = this.appConfig.URL.DASHBOARD;
  private readonly defaultPath: String = this.appConfig.PATHS.DEFAULT;
  private readonly choiceParam = this.appConfig.URL.PARAMS.CHOICE;
  private readonly allowedPaths: String[] = this.appConfig.PATHS.ALLOWED_PATHS;
  private screenName = APP_CONFIG.APPLICATION.ANALYTICS.SCREENS.DECISION;
  private slideConfig : CarouselConfig = new CarouselConfig(false, false, false, false, false);
  private slideNumber = 1;

  @ViewChild("decisionWizard", { static: true }) carousel : NgbCarousel;
  @Output() onDecisionComplete : EventEmitter<Decision[]> = new EventEmitter<Decision[]>();

  visible: Boolean;
  decisions: Decision[] = [];
  finalPage = false;
  firstPage: Boolean = true;
  analyticsConfig = APP_CONFIG.APPLICATION.ANALYTICS; 
  alias = this.analyticsConfig.SCREENS.DECISION;
  stepAction = this.analyticsConfig.ACTIONS.STEP;
  closeAction = this.analyticsConfig.ACTIONS.CLOSE;
  randomAction = this.analyticsConfig.ACTIONS.RANDOM;
  noError: Boolean = true;
  decisionChoices = [{title:"Main"}, {title: "Another"}];

  constructor(private route: ActivatedRoute,
      private router: Router,
      private pathService : PathService, 
      private carouselConfig : NgbCarouselConfig,
      private analytics : AnalyticsService,
      private decisionService: DecisionService,
      private componentService: ComponentService) {
    
        //Set the configuration
      this.componentService.setCarouselConfig(this.carousel, this.carouselConfig);
      
      var that = this;
      this.route.data.subscribe( (data) => {
        let decisions: Decision[] = this.decisionService.mapValuesToDecision(data.decisions);  
        that.setData(decisions);
      });
  }
  
  ngOnInit(): void {
    this.carousel.pause();
    this.analytics.setCurrentScreen(this.screenName);
  }

  /**
   * Recieve information from the parent component.
   * @param values 
   */
  setData(values : Decision[]) {
    this.decisions = values;
    this.visible = true;

    //this.analytics.logEvent(`${this.alias}-${this.stepAction}-${this.slideNumber}`);

    if(this.slideConfig.pause && !!this.carousel) {
      //This pauses the carousel
      this.carousel.pause();
    }
  }

  //The method to start the decision making process
  begin() {}

  //use to decide which option was choosen for each decision
  make(choosen: Choice) {}
  
  //Move to the next decision
  next(skipValidation?: Boolean) {
    let currentSlide = this.slideNumber;
    this.noError = true;
    if(! skipValidation) {
      this.noError = this.isValid();
      
      if(! this.noError) {
        console.log("Failed to pass validation step");
        return;
      }
    }

    this.finalPage = (this.slideNumber == (this.carousel.slides.length - 1) );    
    this.carousel.next();
    this.slideNumber = this.slideNumber + 1;
    this.firstPage = this.slideNumber == 1;
    
    //SKip the middle slide (#2) if the member selected an option.
    if(currentSlide == 1 && !! this.decisions[currentSlide - 1].selectedOption) {
      this.next(true);
    } else {      
      //this.analytics.logEvent(`${this.alias}-${this.stepAction}-${this.slideNumber}`);
    }
  }

  //Move to previous decision
  previous() {
    let currentSlide = this.slideNumber;
    this.carousel.prev();
    this.slideNumber = this.slideNumber - 1;
    this.firstPage = this.slideNumber == 1;
    this.finalPage = false;

    //Skip the middle slide (#2) since the user made a decision and go to the first slide.
    if(currentSlide == 3 && !! this.decisions[0].selectedOption) {
      this.previous();
    } else {
      // let eventName = this.analytics.SELECT_CONTENT;
      // let eventDetails = this.analytics.buildPageSelectContentParams(`${this.alias}-${this.choiceParam}`, choice.title);
      // this.analytics.logEvent(eventName, eventDetails);
    }
  }

  isValid(): Boolean {
    var currentStep: Decision = this.decisions[this.slideNumber - 1];
    if(!! currentStep && !currentStep.required) {
      return true;
    } else if(! currentStep.selectedOption) {
      return false;
    }

    return true;
  }

  //Once all decisions are made, this method finalizes everything
  complete() {
    this.noError = this.isValid();

    if(! this.noError) {
      console.log("Failed to pass the validation checks");
      return;
    }
    // this.analytics.logEvent(`${this.alias}-${this.closeAction}`);
    let queryParams = {};
    let chParamValue = this.decisions[0].selectedOption.name.valueOf();
    let exLvlParam = this.decisions[2].selectedOption.name.valueOf();
    let stateData = { decisions: this.decisions, level: exLvlParam };
    
    queryParams[this.choiceParam] = encodeURIComponent(chParamValue);
    
    /**
     * Final state data:
     * { 
      *  state: {
      *  decisions: Decisions[],
      *  treeMap: [{}] //Data from path service.
      * }
     * }
     */
    let extras: NavigationExtras = {
      queryParams: queryParams,
      state: stateData,
      preserveFragment: true //Keep fragments of the url as we nagivate
    };

    this.getPathDetails(extras);
  }

  //For additional help regarding a particular decision
  help(choice : Choice) {}

  randomChoice() {
    var tempDec: Decision = this.decisions[0];
    var ceiling = (tempDec.choices.length - 1); //this.carousel.slides.length;
    var min = 0;
    //Randomize the first section
    var choiceIndex = Math.floor((Math.random() * ceiling) + min);
    
    this.makeDecision(tempDec, tempDec.choices[choiceIndex]);
    this.complete();
  }

  idkHelp() {
    this.decisions[0].selectedOption = null; 
    this.next(true);
  }
  
  makeDecision(decision: Decision, choice? : Choice) {
    if(!! choice) {
      decision.selectedOption = choice;
    }

    this.noError = true;

    //If we're on the 2nd slide, update the first step as well since that step was skipped.
    if(this.slideNumber == 2) {
      this.decisions[0].choices.forEach((ch) => {
        let choosen = (!!choice.name && (choice.name == ch.name));
        ch.choosen = choosen; //This will set all the other choices to false

        if(choosen) {
          this.decisions[0].selectedOption = ch;
        }
      });
    }

    //Update the choosen option
    decision.choices.forEach((ch) => {
      let choosen = (!!choice.name && (choice.name == ch.name));
      ch.choosen = choosen; //This will set all the other choices to false
    });

    let eventName = this.analytics.SELECT_CONTENT;
    let eventDetails = this.analytics.buildPageSelectContentParams(`${this.alias}-${this.choiceParam}`, choice.title);
    this.analytics.logEvent(eventName, eventDetails);
  }

  getPathDetails(cbData : NavigationExtras) {

    let chosenPath = decodeURIComponent(cbData.queryParams[this.choiceParam]);
    let isPathAllowed: Boolean = this.allowedPaths.includes(chosenPath.toLowerCase());
    let experienceLevel = cbData.state.level;
    let careerPath = `${this.rootPath}/${isPathAllowed? chosenPath : this.defaultPath}/${experienceLevel}`;

    let self = this;
    this.pathService.getList(careerPath).then( (treeMap) => {
      //Set the upside down tree data
      cbData.state["treeMap"] = treeMap; 
  
      //Navigate to the /path page with the choosen decision.
      self.router.navigate( [`${self.pathUrl}`], cbData);

    }, (err) => {
      //TODO: add more steps here.
      console.error(`Fail to pull data from ${careerPath} due to error: `, err);
    })
  }

}
