import { Injectable } from '@angular/core';
import { Choice } from '../models/choice';
import { Decision } from '../models/decision';

@Injectable({
  providedIn: 'root'
})
export class DecisionService {


  constructor(){}

  mapValuesToDecision(rawData): Decision[] {
    let decisions = [];
    let that = this;

    rawData.forEach(data => {
      decisions.push(that.buildDecisionMap(data));    
    });

    return decisions;
  }

  getCurrentPathway(decisions : Decision[]): Choice {
    let choice;
    decisions.forEach((decision) => {
      if(decision.nthDecision == 1) {
        choice = decision.selectedOption;
      }
    });
    return choice;
  }

  private buildDecisionMap(data): Decision {
    var choices: Choice[] = this.buildChoices(data.options);    
    
    return new Decision(data.nthDecision, 
        data.enable, 
        data.required,
        data.question, 
        choices, 
        data.description, 
        data.tips, 
        data.image,
        data.nextTitle, 
        data.nextAction, 
        data.previousTitle, 
        data.previousAction, 
        data.filler);
  }

  private buildChoices(options) : Choice[] {
    var choices : Choice[] = [];

    if(!! options) {
      options.forEach(option => {
        var choice = new Choice(option.enable, option.title, option.name, option.subject, option.image);
        choices.push(choice);
      });
    }

    return choices;
  }

}
