import { Component, OnInit, OnChanges, ViewChild, ElementRef, Input, Output, AfterViewInit, DoCheck, AfterViewChecked, HostListener, ChangeDetectorRef } from "@angular/core";
import { EventEmitter } from "@angular/core";
// import { Event } from "debugger";
import { D3TreeService } from '../../d3/services/d3-tree.service';
import { tree } from "d3";

@Component({
    selector: 'd3-reverse-tree',
    templateUrl: './tree.component.html',
    styleUrls: ['./tree.component.css'],
    host: {
        'class':  'cc-component-container', 
        'id': 'd3-traverse-tree-backdrop'
    },
})
export class TreeVisualComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('chart', {static: false}) private chartContainer: ElementRef;

    @Input() private treeData: any = [];
    @Input() private activeNode: any = [];
    @Output() onNodeChanged: EventEmitter<any> = new EventEmitter();
    @Output() onNodeSelected: EventEmitter<any> = new EventEmitter();

    showChart: Boolean = false;

    constructor( private treeService : D3TreeService, private changeDetector : ChangeDetectorRef ) {
        treeService.setNodeChangedListener( (node) => {
            this.onNodeChanged.emit(node);
        });

        treeService.setNodeSelectedListener( (node) => {
            this.onNodeSelected.emit(node);
        });

        //this.renderOnResize();
        this.ngOnChanges(this.treeData);
    }

    ngOnInit() {}

    ngOnChanges(changes?: any, forceCenter?: Boolean) {
        if(! changes) {
            console.log("Skip change process. Reason state changed: ", changes)
            return;
        }

        if(!! changes.activeNode && !! changes.activeNode.currentValue) {
            this.centerNode(changes.activeNode.currentValue);
            return;
        } 

        if(forceCenter && !! changes) {
            this.centerNode(changes[0]);
            return;
        }

        console.log("d3 changes: ", changes)
        let isTreeReady = !! this.treeData && this.treeData.length > 0;
        //let isChartReady = !! this.chartContainer && !! this.chartContainer.nativeElement
        let loadingComplete = isTreeReady

        if(loadingComplete) {
            // let element = this.chartContainer.nativeElement
            // element.innerHTML = ''            
        }

        let that = this;
        if(isTreeReady) {
            setTimeout(function(){
                that.seedTree(isTreeReady);
            },10)
        }
    }

    ngAfterViewInit() {}

    /**
     * @description This updates fires on each window resize event. Allowing the app to maintain the application at the center
     * @param event 
     */
    @HostListener('window:resize', ['$event'])
    renderOnResize(event?) {
        this.ngOnChanges(this.treeData, true);
    }

    seedTree(loadingComplete : boolean) {
        
        if(!! loadingComplete) {
            this.treeService.createChart(this.chartContainer, this.treeData);
            this.treeService.update();
        }
    }

    /**
     * The purpose of this service is to navigate the selected node to the center
     */
    centerNode(datum: any) {
       this.treeService.centerNode(datum); 
    }

}