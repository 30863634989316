<div class="cc-top pb-5 mt-5" *ngIf="decisions">
    <!-- Splash image of the institution. -->
    <div class="tipper-image">
        <img alt="Celebrate Good News" src="../../../../assets/splash/success.png" />
    </div>
    <h2 class="text-center mb-5">Review And Share</h2>
    <p class="text-center col-md-8 col-sm-12 mx-auto">Awesome! You've made significant progress on deciding on a career path. 
        
    <!-- <strong class="tipper-row">Coursera</strong> is ready to help you through the next phase. Learn more about their <strong class="tipper-row">{{decisions[0]?.selectedOption?.title}}</strong> program today.</p>

    <div class="col mx-auto text-center">
        <a class="btn btn-primary mt-3" href="http://www.coursera.com" target="_blank">Learn More</a>
    </div> -->
</div>
<!-- THIS IS FOR ERROR STATE -->
<div class="row cc-top text-center pd-5 mt-5" *ngIf="errorState">
    <div class="tipper-image">
        <img alt="Not Able To Locate" src="../../../../assets/splash/oops.png" />
    </div>
    <h2>Ooops... Resource Not Found</h2>
    <p class="text-danger mb-5">Unable to locate the details you've requested. Please update your link.</p>
    <a class="btn btn-primary mx-auto mb-5" [routerLink]="[ urlPath.WELCOME ]">Home</a>
</div>

<div class="flex-item card path-summary-content cc-content" *ngIf="!errorState && traversedPath?.length > 0">
    <!-- The advertising details about the organisation.-->
    <div class="card-body mt-5 mb-5 text-center">
        <h4>Your Summary</h4>
        <p>Here are the list of paths you've choosen.</p>
    </div>

    <!-- This is if data was found. -->
    <div class="card-body mb-5 pb-5">
        <div id="summary-{{rung.id}}" 
            class="list-group" 
            *ngFor="let rung of traversedPath | reverse; let indexOfElement=index;"
            appSummaryCollapser="{{rung.id}}"
            [attr.aria-expanded]="false">
        
            <div class="list-group-item my-3 p-3 shadow-sm"
                [ngClass]="indexOfElement > 0? 'not-first': '' ">
                <!-- Details -->
                <div class="top-content">
                    <div class="intro-summary">
                        <h4>{{rung.name}}</h4>
                        <p>General description about this career path</p>
                    </div>
                    <div class="tool-tip">
                        <span>&nbsp;</span>
                    </div>
                </div>
                
                <!-- collapsed content  -->
                <div class="additional-content collapse" 
                    id="collapsable-{{rung.id}}" 
                    #collapse="ngbCollapse" 
                    [(ngbCollapse)]="rung-complete-step">
                    <div class="card">
                        <div class="card-body">
                            <app-step-details 
                                [init]="true"
                                [visible]="true"
                                [previewMode]="true"
                                [steps]="rung.steps" >
                            </app-step-details>
                            <!-- <div class="col col-sm-12" *ngFor="let track of rung.steps">
                                <h6>{{track.title}}</h6>
                                <p>{{track.objective}}</p>

                                <ul class="path-summary-step-details">
                                    <li *ngFor="let item of track.list">
                                        <ng-container *ngIf="item.enable == true">
                                            <p>&#8250; {{item.subject}}</p>
                                        </ng-container>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                </div>
           </div> 
        </div>
    </div>

    <!-- IF nothing was found -->

    <div class="cc-summary-last-survey mb-5" *ngIf="decisions">
        <div class="quick-feedback-wrapper text-center" *ngIf="!feedbackSubmitted">
            <h6>Was this information helpful?</h6>
            <div class="mx-auto mt-3">
                <!-- <a class="btn btn-secondary mr-2 ml-2"  (click)="feedback('No')">Feedback</a> -->
                <a class="btn btn-primary mr-2 ml-2" (click)="feedback('Yes')">Feedback</a>
            </div>
        </div>
        <div class="quick-feedback-wrapper text-center" *ngIf="feedbackSubmitted">
            <p class="text-success pt-3">Thank you for your feedback.</p>
        </div>
    </div>
</div>

<div class="row cc-footer" *ngIf="decisions">  
    
    <button class="col col-sm-4 btn btn-secondary" 
    (click)="restart()">Restart</button>    


    <div class="col col-sm-4">
        &nbsp;
    </div>

    <button *ngIf="menuOptions.length == 1"
        class="col col-sm-4 btn btn-primary" 
        (click)="makeDecision(menuOptions[0])">{{menuOptions[0].title}} 
    
        <span class="badge">
            <img [src]="menuOptions[0].logo"/>
        </span>
    </button>

    <div ngbDropdown 
        #menuDropdown="ngbDropdown" 
        class="col col-sm-4 additional-menu" 
        placement="top-right bottom-rigt"
        *ngIf="menuOptions.length > 1">
        
        <button ngbDropdownAnchor 
            class="btn btn-primary" 
            id="menuOptions" 
            (click)="menuDropdown.open()">{{selectedMenuOption != undefined? selectedMenuOption.title : 'Share' }}</button>
        
        <div ngbDropdownMenu 
            arial-labelledby="menuOptions">            
            
            <button class="dropdown-item"
                *ngFor="let choice of menuOptions" 
                (click)="makeDecision(choice)">
                <span *ngIf="choice.logo" class="badge"><img  [src]="choice.logo" alt="Logo for {{choice.title}}" /></span>
                {{choice.title}}</button>
        </div>
    </div>
    
</div>

<div class="alert-wrapper" *ngIf="showAlert">
    <ngb-alert #summaryAlert type="success" (close)="closeAlert()">{{alertMessage}}</ngb-alert>
</div>

