import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReCaptchaService } from 'angular-recaptcha3';
import { config } from 'process';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaV3Service {
  
  constructor(private recaptchService : ReCaptchaService,
    private httpClient : HttpClient) { }

  captchExecute(): Promise<any> {
  
    return new Promise((resolve, reject) => {
      //Execute upon the recaptcha service is ready.
      this.recaptchService.getReady('en').subscribe(() => { 
        
        //Attempt to validate the token
        this.recaptchService.execute({ action: 'submit'}).then((token) => { //Retrieve the recaptch token
          if(! token) {
            reject({ success: false});
          } else {
            resolve({success: true});
          }
          
          //Submit the token to use.
          // TODO: resolve this section
          // this.submitCaptchaToken(token).subscribe((response) => {
          //   if(! response['success']) {
          //     reject(response);
          //   } else {
          //     resolve(response);
          //   }
          // }); 

        }, (error) => {
          console.log("Captcha token failed error: ", error)
        });
      });

    });

  }

  submitCaptchaToken(token: string): Observable<any> {
    let config = environment.captchaConfig;
    let uri = `https://${config.path.domain}`;
    let url = `${uri}/${config.path.api}`;
    let body = {
      'secret': config.secretKey,
      'response': token
    };

    let httpHeaders : HttpHeaders = new HttpHeaders({
      "Content-Type" : "application/json",
      "Accept" : "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST, GET" 
    });
    let options = {
      headers: httpHeaders
    };

    return this.httpClient.post(url, body, options);
  }  
}
