
/**
 * This is used for the decision made for each introduction wizard step
 */

import { Choice } from "./choice";

export class Decision {
   nthDecision: Number;
   enable : Boolean;
   required: Boolean;
   question: String;
   tips: Object[]; //List of important tips we wish to share with the user about this decision
   advertisments: Object[]; //List of advertisments to include on this decision
   image: String;
   choices : Choice[]; //List of choices
   description: String;
   previousTitle: String;
   previousDirection: String;
   nextTitle: String;
   nextDirection: String; 
   filler: String;
   selectedOption: Choice | undefined;  //This is not apart of the firebase data model. This is just used to keep the persistent local storage.
   
   constructor(nthDecision, enable: Boolean, required: Boolean, question: String, choices: Choice[], description: String, 
         tips: Object[], image: String, nextTitle, nextDirection, previousTitle, previousDirection, filler?) {

    this.nthDecision = nthDecision;
    this.enable = enable;
    this.required = required;
    this.question = question;
    this.choices = choices;
    this.description = description;
    this.tips = tips;
    this.image = image;
    this.nextTitle = nextTitle;
    this.nextDirection = nextDirection;
    this.previousTitle = previousTitle;
    this.previousDirection = previousDirection
    this.selectedOption = undefined;
    if(!!filler) {
      this.filler = filler;
    }
   }

   setTips(tips) {
      this.tips = tips;
   }
}