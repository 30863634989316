const CONFIG = {
  RELEASE: {
    VERSION: "beta",
    NAME: "Testing"
  },
  N : 30,
  SPECTRUM: [
    "rgb(176,212,243)",
    "rgb(128,186,236)",
    "rgb(77,158,228)",
    "rgb(38,137,223)",
    "rgb(0,116,217)",
    "rgb(0,106,197)"
  ],
  
  APPLICATION: {
    FEEDBACK: {
      WIDGET_ID: "4080200b-6fbc-459b-ae2e-437611b962f8"
    },
    META_DATA : {
      TITLE: "Your IdealCareerPath",
      DESCRIPTION: ""
    },
    AUTH: {
      SESSION_NAME: "icp",
      FB_USER_ROOT_PATH: "/user-data",
      REDIRECT_PATH: "/auth"
    },
    DECISION: {
      ROOT: "decisions"
    },
    URL: {
      AUTH: "/auth",
      DECISION: "/start",
      DASHBOARD: "/path",
      WELCOME: "/welcome",
      SUMMARY: "/summary",
      COOKIE_POLICY: "/cookie-policy",
      PARAMS: {
        REDIRECT_TO: "rdt",
        CHOICE: "cd",
        SUMMARY_ID: "sid",
        AUTO_LOGOUT: "alo"
      },
      SOCIAL_MEDIA: {
        TWITTER: "https://twitter.com/ICareerpath",
        FACEBOOK: "https://www.facebook.com",
        INSTAGRAM: "https://www.instagram.com/idealcareerpath"
      }
    },
    PATHS: {
      ROOT: "paths",
      SUMMARY: "summary",
      DEFAULT: "default",
      // TODO: Update this list when a new career path comes on board. 
      ALLOWED_PATHS: [
        "business",
        "health",
        "information-technology",
        "engineering"
      ]
    },
    NODES : {
      DEFAULT: {
        RIGHT_NODE: "Right Node",
        LEFT_NODE: "Left Node",
        START_NODE: "start"  
      }
    },
    ADDITIONAL: {
      SAVE_SESSION_NAME : "ssd",
      COOKIE_NAME: "ccicp",
      MODAL_SELECTION_PARENT: "div.modal-container"
    },
    ANALYTICS: {
      LOG_PARAM : "cc_param",
      SCREENS: {
        STEP_DETAILS: "step",
        DECISION: "decision",
        AUTHENTICATION: "auth",
        SUMMARY: "summary",
        PATHWAY: "path",
        FEEDBACK: "feedback"
      },
      ACTIONS: {
        STEP: "step",
        CLOSE: "close",
        OPEN: "open",
        COMPLETE: "complete",
        RANDOM: "idk",
        PREVIOUS: "prev",
        NEXT: "next",
        MOTIVATOR: "motivator"
      },
      GTAG_EVENTS: {
        PAGE_VIEW: "page_view",
        SELECTED_CONTENT: "select_content"
      },
      GTAG_PARAMS: {
        CONTENT_TYPE: "content_type",
        ITEM_ID: "item_id"
      }
    }
  }
}

export default CONFIG;
