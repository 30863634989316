
export class Supplimental {
    enable: Boolean = false;
    type: String;
    message: String;
    url: String;
    
    constructor(enable, type, message, url) {
        this.enable = enable;
        this.type = type;
        this.message = message;
        this.url = url;
    }
   
}

