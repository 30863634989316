<div class="row mb-sm-5 md-md-5 md-lg-5 md-xs-3" *ngIf="showNav">
    <button class="col col-md-4 col-sm-5 btn btn-primary" 
        *ngIf="lastNode != true"
        ngbTooltip="{{intro?.tip}}"
        #leftIntro="ngbTooltip"
        (click)="goLeft(leftPath)">{{leftPath.name}}
    </button>    

    <button class="col col-md-4 col-sm-5 btn btn-secondary" 
        *ngIf="lastNode == true"
        (click)="restart()">Restart</button>

    
    <div class="col col-md-4 col-sm-1">
        &nbsp;
        <app-path-intro [showIntro]="false" 
            [title]="'Navigation'" 
            [message]="'Navigate to the left or right most option in the career path using this menu options.'" >
        </app-path-intro>
    </div>

    <!-- The popover details -->
    <div ngbDropdown 
            #morePaths="ngbDropdown" 
            class="col col-md-4 col-sm-5"
            placement="top-right top-left"
            disableTooltip="{{intro?.step != 2}}"
            ngbTooltip="{{intro?.tip}}"
            #rightIntro="ngbTooltip"
            *ngIf="lastNode != true && additionalPaths?.length > 1">
        <button ngbDropdownToggle 
                class="col col-sm-12 btn btn-primary" 
                id="additionalPathsOptions"      
                (click)="morePaths.open()">{{ selectedOption != null ? selectedOption : defaultRightPath }}
        </button>
        <div ngbDropdownMenu arial-labelledby="pathOptions">
            <button 
                class="col col-sm-12 dropdown-item" 
                *ngFor="let apath of additionalPaths;" 
                (click)="goRight(apath)">{{apath.name}}
            </button>
    
        </div>
    </div>
    <button class="col col-md-4 col-sm-5 btn btn-primary" 
        *ngIf="lastNode != true && additionalPaths?.length == 1"
        (click)="goRight(additionalPaths[0])"
        disableTooltip="{{intro?.step != 2}}"
        ngbTooltip="{{intro?.tip}}"
        #rightIntro="ngbTooltip"
        >{{additionalPaths[0].name}}

    </button> 

    <button class="col col-md-4 col-sm-5 btn btn-primary" *ngIf="lastNode == true"
        (click)="finish()">Summary</button>
    
</div>
