/**
 * This is used for the introduction wizard
 */

import { now } from "d3-timer";

export class Feedback {
   version: String;
   userId: String;
   optOut: Boolean;
   rating: number;
   message: String;
   date : String;
   
   constructor(userId: String, version: String, optOut: Boolean, rating?: number, message?: String) {
      this.userId = userId
      this.version = version;
      this.optOut = optOut;
      if(!! rating) 
         this.rating = rating;
      
      if(!! message)
         this.message = message;
         
      this.date = new Date().toString();
   }
}