import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import CONFIG from 'src/app/app.config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private config = environment.moosendConfig;
  private apiUri = `${this.config.domain}/${this.config.version}`;
  private subscribersPath = this.config.paths.subscribers;
  private subscribePath = this.config.paths.subscribe;
  private mailingListId = this.config.mailingListID;
  private format = this.config.format;
  private appVersion = CONFIG.RELEASE.VERSION;

  private httpHeaders: HttpHeaders;
  private headerPayloads = {
    "Content-Type" : "application/json",
    "Accept" : "application/json",
    "Access-Control-Allow-Origin": `https://${this.config.domain}`
  }

  constructor(private httpClient: HttpClient) {
    this.init();
  }

  init() {
    if(! this.httpHeaders) {
      this.httpHeaders = new HttpHeaders();
    }

    Object.keys(this.headerPayloads).forEach(key => {
      this.httpHeaders.set(key, this.headerPayloads[key]);
    });
  }

  subscribe(email: String) : Observable<any>{
    //https://api.moosend.com/v3/subscribers/MailingListID/subscribe.Format?apikey=bafe55f4-e53e-4651-8164-c6d6ff05081b
    var url = `https://${this.apiUri}/${this.subscribersPath}/${this.mailingListId}/${this.subscribePath}.${this.format}?apiKey=${encodeURIComponent(this.config.apiKey)}`;
    var body = {
      "Name": "",
      "Email": email,
      "HasExternalDoubleOptIn": true,
      "CustomFields": [
        `App_Version=${this.appVersion}`
      ]
    };

    let options = {
      headers: this.httpHeaders
    }

    return this.httpClient.post(url, body, options);
  }
}
