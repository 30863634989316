import { Component, OnInit, OnChanges, SimpleChanges, ViewChild, ComponentRef, Output, EventEmitter, Input } from '@angular/core';
import { Step } from '../../d3/models/step';
import { NgbModalRef, NgbCarouselConfig, NgbCarousel, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CarouselConfig } from 'src/app/d3/models/carouselConfig';
import { ModalConfig } from 'src/app/d3/models/modalConfig';
import CONFIG from 'src/app/app.config';
import { AnalyticsService } from 'src/app/services/tracking/analytics.service';
import { ComponentService } from 'src/app/services/component/component.service';

@Component({
  selector: 'app-step-details',
  templateUrl: './step-details.component.html',
  styleUrls: ['./step-details.component.scss'],
  host: {
    'class':  'container cc-component-container', 
    'id': 'step-details-container'
  },
  animations: [],
  providers: [NgbCarouselConfig] //Add carousel config to allow for config settings
})
export class StepDetailsComponent implements OnInit, OnChanges{
  //Config for carousel
  private slideConfig : CarouselConfig = new CarouselConfig(false, false, false, false, false);
  private mConfig : ModalConfig = new ModalConfig();
  private carousel : NgbCarousel;
  private modalContainerSelector = CONFIG.APPLICATION.ADDITIONAL.MODAL_SELECTION_PARENT;
  private analyticsConfig = CONFIG.APPLICATION.ANALYTICS
  private stepAlias = this.analyticsConfig.SCREENS.STEP_DETAILS;
  private closeAlias = this.analyticsConfig.ACTIONS.CLOSE;
  private nextAlias = this.analyticsConfig.ACTIONS.NEXT;
  private prevAlias = this.analyticsConfig.ACTIONS.PREVIOUS;
  private screenName = CONFIG.APPLICATION.ANALYTICS.SCREENS.STEP_DETAILS;
  
  
  compInstance : ComponentRef<StepDetailsComponent>;
  activeModalsRef : NgbModalRef[] = [];
  slideNumber = 1;
  finalPage: Boolean = false;
  jobTitle: string;
  
  @Input() visible: Boolean = false;
  @Input() steps: Step[] = [];
  @Input() init: Boolean = false;
  @Input() previewMode: Boolean = false;
  
  @Output() onStepsComplete : EventEmitter<Step[]> = new EventEmitter<Step[]>();

  //This sets the carousel as it is hidden on initial load (via ngIf=visible)
  @ViewChild("stepWizard") set content(content : NgbCarousel) {
    if(content) {
      this.carousel = content;
      this.carousel.pause();
    }
  } 

  constructor(private carouselConfig : NgbCarouselConfig, 
      private modalConfig : NgbModalConfig,
      private analytics : AnalyticsService,
      private componentService: ComponentService) {
    
    this.componentService.setCarouselConfig(this.carousel, this.carouselConfig);
    this.componentService.setModalConfig(this.modalConfig, this.modalContainerSelector);
    this.analytics.setCurrentScreen(this.stepAlias);        
  }

  ngOnInit() {}

  
  ngOnChanges(changes: SimpleChanges) {
    console.log("detecting changes: ", this.steps);
  }

  showNode(steps: Step[], compInstance? : ComponentRef<StepDetailsComponent>, opts?: Object) {
    this.steps = steps;
    this.compInstance = compInstance;

    if(!!opts && opts['name']) {
      this.jobTitle = opts['name'];
    }
    this.show();
  }

  show() {
    this.openModal();
  }

  close() {
    this.closeModal();
  }

  openModal() {
    this.visible = true;
  }

  closeModal() {
    this.logEvent(this.stepAlias, this.closeAlias);
    this.onStepsComplete.emit(this.steps);
  }

  //Move to the next decision
  next() {
    this.finalPage = (this.slideNumber == (this.carousel.slides.length - 1) )

    this.carousel.next();
    this.carousel.pause();
    this.slideNumber = this.slideNumber + 1;  
    this.logEvent(this.stepAlias, this.nextAlias, this.slideNumber - 1);
  }

  //Move to previous decision
  previous() {
    if(this.slideNumber == 1) {
      return;
    }
    
    this.carousel.prev();
    this.carousel.pause();
    this.slideNumber = this.slideNumber - 1;
    this.finalPage = false;
    
    this.logEvent(this.stepAlias, this.prevAlias, this.slideNumber + 1);
  }

  //Once all decisions are made, this method finalizes everything
  complete() {
    this.closeModal();
    this.logEvent(this.screenName, this.analyticsConfig.ACTIONS.COMPLETE);
  }

  logEvent(section, action, additional?) {
    additional = (!! additional)?`-${additional}`:'';
    let eventName = this.analytics.SELECT_CONTENT;
    let eventDetails = this.analytics.buildPageSelectContentParams(`${this.stepAlias}`, `${action}${additional}`);
    this.analytics.logEvent(eventName, eventDetails);
  }
}
