import { Injectable } from '@angular/core';
import CONFIG from 'src/app/app.config';
import { User } from 'src/app/d3/models/user';
import { FirebaseService } from 'src/app/d3/services/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class UserdataService {
  private fb_session_name = CONFIG.APPLICATION.AUTH.SESSION_NAME;
  private version = CONFIG.RELEASE.VERSION;
  private userRoot = CONFIG.APPLICATION.AUTH.FB_USER_ROOT_PATH;
  private dbRef : firebase.default.database.Reference;
  private promise : Promise<Object>;
  private currUser : User;

  constructor(private fbService : FirebaseService) { 
    this.dbRef = this.fbService.db(`${this.version}/${this.userRoot}`);
  }

  getUserData(uId: string): Promise<any> {
    var that = this;
    this.promise = new Promise(function(resolve, reject) { 
      that.dbRef.child(uId).on("value", (snapshot) => {
        let udata = snapshot.val();

        if(! udata) {
          reject(`Failed to fetch user data for:${uId}`);
        }

        resolve(udata);
      });
    });

    return this.promise;
  }

  removeUserData(uId: string): Promise<any> {
    var that = this;
    return this.promise = new Promise(function(resolve, reject) {
      that.dbRef.child(uId).remove().then((onComplete)=> {
        console.log(`Removing user data complete`);
        resolve(onComplete)
      }, (onError)=> {
        console.error(`Failed to remove user data due to error ${onError}`);
        reject(onError);
      })
    })

    
  }

  //Set user data
  protected saveUserData(uId) : Promise<any> {
    let parsedData = JSON.parse(JSON.stringify(this.currUser));

    return this.dbRef.child(uId).update(parsedData);
  }

  //Update user data with additional details
  setTraversedPath(uId : String, paths) : Promise<any> {
    if(! this.currUser) {
      this.currUser = this.retrieveLocalData();
      this.sanitizeUserData();
    }
    
    this.updateLocalData({'paths': paths});

    return this.saveUserData(uId);
  }

  retrieveLocalData(): User { 
    if(!! localStorage.getItem(this.fb_session_name)) {
      return JSON.parse(localStorage.getItem(this.fb_session_name));
    } else {
      return null;
    }
  }

  saveLocalData(sessionData: User) {
    localStorage.setItem(this.fb_session_name, JSON.stringify(sessionData));
  }

  updateLocalData(obj : any) {
    let user : User = this.retrieveLocalData();
    
    for(var key in obj) {
      user[key] = obj[key];
    }

    this.saveLocalData(user);
    this.currUser = this.retrieveLocalData();
    
    //Sync in cloud
    this.saveUserData(this.currUser.userId);
  }

  sanitizeUserData() {
    let params = ["refreshToken", "token"]; 

    for(var key in params) {
      if(!! this.currUser[key]) {
        this.currUser[key] = undefined;
      }
    }
  }

  removeLocalData() {
    localStorage.removeItem(this.fb_session_name);
  }
}
