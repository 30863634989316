import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appSummaryCollapser]'
})
export class SummaryCollapserDirective implements AfterViewInit {
  private readonly EXCLUDE_CLASSES = ['btn'];

  @Input('appSummaryCollapser')
  categoryId: string;

  public collapsed: boolean;

  constructor(private element: ElementRef) { 
    this.collapsed = true; //Set collapse by default
  }

  ngAfterViewInit() {
    const el = document.querySelector("#collapsable-"+ this.categoryId);

    if (el.classList.contains("show") && this.collapsed) {
      el.classList.remove("show");
    }
  }

  @HostListener("click", ['$event']) 
  onSummaryClick($event: KeyboardEvent | MouseEvent) {
    var className = "active"
    var element = this.element.nativeElement;

    if(this.excludeFor($event.target)) {
      return false;
    }

    if(! element.classList.contains(className)) {
      element.classList.add(className);
    } else {
      element.classList.remove(className)
    }

    const el = document.querySelector("#collapsable-" + this.categoryId);
    if (this.collapsed) {
      el.classList.add("show");
    } else {
      var actvCls = ["show"]
      el.classList.remove(...actvCls);
    }
    this.collapsed = !this.collapsed;
  }

  excludeFor(etarget): Boolean {
    let exclude = false;
    this.EXCLUDE_CLASSES.forEach( (cl) => {

      if(etarget.classList.contains(cl)) {
        exclude = true;
        return;
      }
    });
    
    return exclude;
  }

}
