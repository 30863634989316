import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import APP_CONFIG from 'src/app/app.config';
import { PathService } from 'src/app/d3/services/path.service';

@Injectable({
  providedIn: 'root'
})
export class PathResolver implements Resolve<any>{
  private readonly pathConfig = APP_CONFIG.APPLICATION.PATHS; 
  private readonly rootPath: String = this.pathConfig.ROOT;
  private readonly allowedPaths: String[] = this.pathConfig.ALLOWED_PATHS;
  private readonly defaultPath: String = this.pathConfig.DEFAULT;
  private readonly choiceParam = APP_CONFIG.APPLICATION.URL.PARAMS.CHOICE;
  
  constructor(private pathService: PathService, 
    private route: ActivatedRoute,
    private router: Router) {
      
    this.route.data.subscribe( (data) => {
      console.log("PATHRESOLVE: DATA: ", data);
    })
  }
  
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let chosenPath = decodeURIComponent(route.queryParams[this.choiceParam]);
    let isPathAllowed: Boolean = this.allowedPaths.includes(chosenPath.toLowerCase());
    if(! isPathAllowed) {
      console.error(`Failed to load as ${chosenPath} is not in the approved list`)
    }

    let careerPath = `${this.rootPath}/${isPathAllowed? chosenPath : this.defaultPath}`;

    return this.pathService.getList(careerPath);
  }
}
