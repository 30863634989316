import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Router } from '@angular/router';
import { NgbDropdownToggle, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import APP_CONFIG from '../../app.config'

@Component({
  selector: 'app-path-navigation',
  templateUrl: './path-navigation.component.html',
  styleUrls: ['./path-navigation.component.scss'],
  providers: [NgbDropdownToggle],
  host: {
    'class':  'container', 
    'id': 'path-navigation-backdrop'
},
})
export class PathNavigationComponent implements OnInit {
  private summaryPath = APP_CONFIG.APPLICATION.URL.SUMMARY;
  private readonly rootName  = APP_CONFIG.APPLICATION.NODES.DEFAULT.START_NODE;
  
  private readonly complete_status = "complete";  
  static readonly MODE_RESTART = "restart";
  static MODE_PARAM = "mode";
  lastNode: Boolean = false;
  defaultRightPath = "Others ...";
  selectedOption : string = null;
  rightIntro: NgbTooltip;
  leftIntro: NgbTooltip;
  
  @Input() showNav: Boolean = false;
  @Input() data: any;
  @Input() leftPath: any;
  @Input() additionalPaths: any[] = [];
  @Input() intro: any = {};

  @Output() onNextRung : EventEmitter<any> = new EventEmitter<any>();
  @Output() onComplete : EventEmitter<any> = new EventEmitter<any>();
  @Output() onIntroNext : EventEmitter<any> = new EventEmitter<any>(); //emits each time we move pass an intro

  
  @ViewChild("rightIntro") set rcontent(content : NgbTooltip) {
    if(content) {
      this.tooltipHandler(this.rightIntro, content);
    }
  }
  
  @ViewChild("leftIntro") set lcontent(content : NgbTooltip) {
    if(content) {
      this.tooltipHandler(this.leftIntro, content);
    }
  } 



  constructor() {
    // this.intro = {
    //     "enable": true,
    //     "step": 1,
    //     "tip": "Welcome, let's show you around. First is the left navigation option. Click to choose the lest most option"
    //   };
  }

  ngOnInit(): void {
    this.setInitialRungs();
  }

  setInitialRungs(mode?:string) {
    //Set the root as the fruns rung
    if(!! this.data[0] && this.data[0].level == 0){
      var rungData = { 
        isStart: true, 
        data: this.data[0]
      };

      if(!! mode) {
        rungData[PathNavigationComponent.MODE_PARAM] = PathNavigationComponent.MODE_RESTART;
      }

      this.onNextRung.emit(rungData);
    }

    this.probe(this.data);
  }

  goLeft(current): void {
    var rungData = {isStart: false, data: current};
    this.onNextRung.emit(rungData);
    this.probe(this.data, current);
  }

  goRight(current: any[] | any): void {
    var rungData= {isStart: false, data: current};
    this.onNextRung.emit(rungData);
    this.probe(this.data, current); 
  }

  restart(): void {
    this.lastNode = false; // reset the last node.
    this.setInitialRungs(PathNavigationComponent.MODE_RESTART);
  }

  finish(): void {
    this.onComplete.emit({status: this.complete_status})
  }

  /**
   * The objective of this method is to scan through the tree (list of opportunities) and identify the next one in the list.
   * @param nodes 
   * @param parent 
   */
   probe(nodes: any[], currentNode?) {
    var leftFound = false;
    var left = null;
    var right = [];

    if(! currentNode) { //Assume we are at the start.
      currentNode = {
        child: this.rootName
      };
    }

    nodes.forEach(node => {
      var isChildNode = (currentNode.child != "" && node.parent == currentNode.child);
      
      if(isChildNode) {
        if(left == null) { //First set the left.
          left = node;
          this.leftPath = left;
          leftFound = true;
        } else { //Then add to the additional paths
          right.push(node);
        }
      }
    });

    this.additionalPaths = right;
    if(! leftFound) {
      this.lastNode = true;
    } 
  }

  tooltipHandler(el : NgbTooltip, content) {
    var that = this;
    
    el = content;
    el.autoClose = false;
    el.triggers = "click";
    el.openDelay = 2000;
    el.open();

    el.hidden.subscribe( (d) => {
      that.onIntroNext.emit(d);
    })
  }

}