<div class="auth-wrapper">
    <!-- The top -->
    <div class="cc-top">
        <div class="user-image pb-5" *ngIf="isLoggedIn">
            <img src="{{ userDetails.photoUrl }}" alt="Member User Logo"/>
        </div>
        <h2 *ngIf="! isLoggedIn">Login</h2>
        <h2 *ngIf="isLoggedIn">Hi {{!!userDetails.displayName?userDetails.displayName: 'There'}}!</h2>
        <small *ngIf="isLoggedIn">Kickstart your journey now!</small>
        <small *ngIf="! isLoggedIn">Login and lets get started!</small>
    </div>

    <!-- The content -->
    <div class="cc-content">
        <!-- If not logged in -->
        <div *ngIf="! isLoggedIn">

            <div id="firebaseui-auth-container"></div>
            <div id="loader" class="text-center">Loading...</div>
            <hr class="hr-text" data-content="OR" *ngIf="enableEmailAuth">
            <form class="form-submit mt-5 w-80" id="signInForm" 
                [formGroup]="signInForm" 
                #ngForm="ngForm" 
                (ngSubmit)="sendRegistrationLink()" 
                *ngIf="enableEmailAuth">

                <input type="email" 
                    class="form-control-input col text-center" 
                    id="email"
                    name="email"
                    formControlName="email"
                    value="" 
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    ngbTooltip="Login by entering your email, then click on the email verification link.">
               
                <p *ngIf="checkEmail" class="text-center">Please check your email.</p>
                <div class="form-group text-center mt-3">
                    <button type="submit" class="btn-primary center login-submit-button">Signup / Login</button>
                </div>  
            </form>
            
        </div>
        <!-- If member is logged in -->
        <div class="sub-container" *ngIf="isLoggedIn">
            <div class="row">
                <button type="button" class="btn btn-primary" (click)="goTo( urlPaths.DECISION )">Start</button>    
            </div>
            <div class="row">
                <button type="button" class="btn btn-secondary" (click)="logout()">Logout</button>
            </div>
        </div>
    </div>
</div>
