<header id="header" class="header">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="text-container">
                    <h1 class="h1-large">Undecided About Your Career?</h1>
                    <p class="p-large"><strong>IdealCareerPath</strong> is here to help you <strong>find your perfect fit</strong> with our three step process.</p>
                    <a class="btn-solid-lg" [routerLink]="[ urlConfig.DECISION ]">Get Started</a>
                    <a class="btn-outline-lg page-scroll" href="/welcome#benefits">Learn More</a>
                </div> <!-- end of text-container -->
            </div> <!-- end of col -->
            <div class="col-lg-7">
                <div class="image-container">
                    <img class="img-fluid" src="../../../assets/images/icons/header.png" alt="alternative" loading="lazy" />
                </div> <!-- end of image-container -->
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</header> <!-- end of header -->
<!-- end of header -->

<!-- Features -->
<div id="benefits" class="cards-1">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="h2-heading">Knowledge Is Power.</h2>
                <p class="p-heading">Identifying and prioritizing your career goals maybe challenging yet rewarding. Here are a three direct benefits of knowing.</p>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
        <div class="row">
            <div class="col-lg-12">
                
                <!-- Card -->
                <div class="card">
                    <div class="card-icon-wrapper">
                        <div class="card-icon">
                            <span class="fas fa-headphones-alt green">★</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <h4 class="card-title">Increase Confidence</h4>
                        <p>Knowing the various career options will result in an increase in your belief that your career goals are realistic.</p>
                        <!-- <a class="read-more no-line" href="article.html">Learn more <span class="fas fa-long-arrow-alt-right">★</span></a> -->
                    </div>
                </div>
                <!-- end of card -->

                <!-- Card -->
                <div class="card">
                    <div class="card-icon-wrapper">
                        <div class="card-icon">
                            <span class="far fa-clipboard blue">★</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <h4 class="card-title">Set Realistic Goals</h4>
                        <p>This will increase your motivation by defining a clear path you can travel in order to achieve your target.</p>
                        <!-- <a class="read-more no-line" href="article.html">Learn more <span class="fas fa-long-arrow-alt-right">★</span></a> -->
                    </div>
                </div>
                <!-- end of card -->

                <!-- Card -->
                <div class="card">
                    <div class="card-icon-wrapper">
                        <div class="card-icon">
                            <span class="far fa-comments blue">★</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <h4 class="card-title">Clear Requirements</h4>
                        <p>Having a checklist of the necessary steps can help you work efficiently in reaching your goals.</p>
                        <!-- <a class="read-more no-line" href="article.html">Learn more <span class="fas fa-long-arrow-alt-right">★</span></a> -->
                    </div>
                </div>
                <!-- end of card -->

            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of cards-1 -->
<!-- end of features -->

<!-- The Mission and Purpose -->
<div id="details" class="basic-2">
    <div class="container">
        <div class="row">
            <div class="col-lg-9">
                <div class="intro">
                    <h2>Your Career Is Important. <br/>Find Yours In 3 Simple Steps.</h2>
                    <p>To gain solid understanding of various career options, you need a tool capable of sorting and categorizing the ever changing career options. <strong>IdealCareerPath</strong> is here to help you understand the various career options and how they're related.</p>
                </div> <!-- end of intro -->
            </div> <!-- end of col -->
        </div> <!-- end of row -->
        <div class="row">
            <div class="col-lg-7">
                <div class="image-container">
                    <img class="img-fluid" src="../../../assets/images/icons/choose.png" alt="alternative" loading="lazy">
                </div> <!-- end of image-container -->
            </div> <!-- end of col -->
            <div class="col-lg-5">
                <div class="text-container">
                    <div class="section-title">Step 1</div>
                    <h2>Choose Your Interest</h2>
                    <p>Choose the industry or profession you're interested in exploring or pursuing.</p>
                    <!-- <a class="btn-solid-reg popup-with-move-anim" href="#details-step-2">Next</a> -->
                </div> <!-- end of text-container -->
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of basic-2 -->
<!-- end of details 1 -->

<!-- Details 2 -->
<div class="basic-3" id="details-step-2">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="text-container">
                    <div class="section-title">Step 2</div>
                    <h2>View Career Options</h2>
                    <p>Achieving a particular career goal may have pre-qualifications, we will help you understand and navigate those requirements. You will also have fun our options while you learn about: </p>
                    <ul class="list-unstyled li-space-lg">
                        <li class="media">
                            <i class="fas fa-square">⊚</i>
                            <div class="media-body">Job description of each career.</div>
                        </li>
                        <li class="media">
                            <i class="fas fa-square">⊚</i>
                            <div class="media-body">Accomplishments required for advancement.</div>
                        </li>
                        <li class="media">
                            <i class="fas fa-square">⊚</i>
                            <div class="media-body">Knowledge to be gained at each phase.</div>
                        </li>
                    </ul>
                </div> <!-- end of text-container -->
            </div> <!-- end of col -->
            <div class="col-lg-7">
                <div class="image-container">
                    <img class="img-fluid" src="../../../assets/images/icons/view_options.png" alt="alternative" loading="lazy">
                </div> <!-- end of image-container -->
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of basic-3 -->
<!-- end of details 2 -->


<!-- Details 3 -->
<div class="basic-4" id="details-step-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <div class="image-container">
                    <img class="img-fluid" src="../../../assets/images/icons/celebrate.png" alt="alternative" loading="lazy">
                </div> <!-- end of image-container -->
            </div> <!-- end of col -->
            <div class="col-lg-5">
                <div class="text-container">
                    <div class="section-title">Step 3</div>
                    <h2>Review and Share</h2>
                    <p>Taking a quick peek at the steps you've taken and then sharing this tool with your friends are the best ways of getting others invovled.</p>
                    <a class="btn-solid-reg" [routerLink]="[ urlConfig.DECISION ]">Start now</a>
                </div> <!-- end of text-container -->
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of basic-4 -->
<!-- end of details 3 -->

<!-- Newsletter -->
<div class="form-1">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="h2-heading">Subscribe And Follow Us</h2>
                <p class="p-heading">Be part of the story and follow us on Twitter or Instagram 
                    <a class="anchor-link" href="https://linktr.ee/idealcareerpath" target="_blank">@idealcareerpath</a> and subscribe to keep up to date with new information.</p>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
        <div class="row">
            <div class="col-lg-12">
                
                <!-- Newsletter Form -->
                <form [formGroup]="subscriptionForm" #ngForm="ngForm" (ngSubmit)="onSubscribe()">
                    <div class="form-group mail">
                        <input type="email" 
                            class="form-control-input" 
                            id="subscriberEmail"
                            name="subscriberEmail"
                            formControlName="subscriberEmail" 
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                        <label class="label-control" for="subscriberEmail">Email address</label>
                        <div class="help-block with-errors"></div>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="form-control-submit-button">Subscribe</button>
                    </div>
                    <div class="form-message">
                        <span id="msg-error" 
                            class="text-danger text-center" 
                            *ngIf=" subscriptionSubmitted == true &&
                                subscriptionForm.controls['subscriberEmail'].invalid ">Please enter valid email address.</span>
                        
                        <span id="msg-error" 
                            class="text-success text-center" 
                            *ngIf="! subscriptionForm.controls['subscriberEmail'].invalid && subscriptionSubmitted == true">Thank you for subscribing!</span>
                        <span class="text-danger text-center"
                        *ngIf="captchaError">
                            Failed to confirm that you're not a robot.
                        </span>
                        
                    </div>
                    <recaptcha 
                        [size]="'invisible'"
                        [hide]="false" >
                    </recaptcha>
                </form>
                <!-- end of newsletter form -->
                
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of form-1 -->
<!-- end of newsletter -->