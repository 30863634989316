/**
 * This is used for the introduction wizard
 */

export class Choice {
   enable: Boolean;
   title: String;
   description: String;
   image: String;
   name: String;
   //options: String[]; //the list of dropdown options
   choosen: Boolean;
   
   constructor(enable, title, name, description, image) {
    this.enable = enable;
    this.title = title;
    this.name = name;
    //this.options = options;
    this.image = image;
    this.description = description;
    this.choosen = false; //Set choose to be false by default.
   }

   choose() {
      this.choosen = true;
   }

   deselect() {
      this.choosen = false;
   }
}