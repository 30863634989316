import { Injectable } from '@angular/core';
import { Step } from 'src/app/d3/models/step';
import { Supplimental } from 'src/app/d3/models/supplimental';

@Injectable({
  providedIn: 'root'
})
export class AdvertisementService {
  sponsor: String;
  advertisements: Array<Object>;
  supplementals: Array<Supplimental>;
  
  enableAds: Boolean = true;


  constructor() { }

  setSponsor(sponsor: String) {

  }

  injectAdvs(steps: Step[]) {
    if(! this.enableAds) {
      return;
    }
    
    let adStep: Step = new Step(Step.STEP_TYPE_ADV, "Main Advertisement", "advs/step-berkshir-tmp.jpg", "Learn about our career programs at", "Let us help you", [], []);

    //First step is to get the advertisement
    steps.push(adStep);

    //Inject the adv at the respective section in the configuration.
  }

  /**
   * 
   * @returns 
   */
  injectSupplimentals(stepNumber): Array<Supplimental> {
    
    return this.supplementals;
  }

  getAdvs() {
    
  }

  transposeAdsToStep() {
    
  }


}
