import { Feedback } from "./feedback";

/**
 * This is the authenticated details for the logged in user.
 */
export class User {
    provider: String = "";
    displayName: String = "";
    email: String = "";
    emailVerified: Boolean = false;
    photoUrl: String = "";
    token: String = "";
    refreshToken: String = "";
    userId: String = "";
    paths: Object[];
    feedbacks: Feedback[] = []; //Contain two properties: rating and message
    

    public constructor(userId: String, displayName?: String, email?: String, 
                token?: String, refreshToken?: String, emailVerified?: Boolean, 
                photoUrl?: String, provider?: String) {
        
        this.userId = userId;
        this.displayName = displayName;
        this.email = email;
        this.token = token;
        this.refreshToken = refreshToken;

        if(!! emailVerified) {
            this.emailVerified = emailVerified;
        }
        if(!! photoUrl) {
            this.photoUrl = photoUrl;
        }
        if(!! provider) {
            this.provider = provider;
        }
    }

    public setPaths(paths) {
        this.paths = paths;
    }
}