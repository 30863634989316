<div class="cookie-notice" *ngIf="showNotice">
    <div class="container">   
        <p class="col col-sm-12">We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.</p>

        <span id="action_section" >
            <a class="btn btn-success offset-lg-1" (click)="close()">Ok</a>
            <a class="btn btn-success" [routerLink]="[ cookie_policy_url ]">Privacy Policy</a>
        </span>
        <button (click)="close()" class="close-icon close" aria-label="Close">
            <span aria-hidden="true">⊹</span>
        </button>
    </div>
</div>
