import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/d3/models/user';
import { UserdataService } from 'src/app/services/user/userdata.service';

@Component({
  selector: 'app-goodbye',
  templateUrl: './goodbye.component.html',
  styleUrls: ['./goodbye.component.scss']
})
export class GoodbyeComponent implements OnInit {

  user : User
  hasError: Boolean;
  isComplete: Boolean;
  
  constructor(private userService: UserdataService) {}

  ngOnInit(): void {
    var that = this;
    that.hasError = true;
    that.user = this.userService.retrieveLocalData();
    if(! that.user) {
      this.hasError = true;
      
      return;
    }

    //Get user data
    this.userService.getUserData(that.user?.userId?.toString()).then((ok) => {
      var tempUser = <User>ok;

      //Ensuring that there is a match before performing user delete.
      if(! tempUser.userId || tempUser.userId != that.user.userId) {
        that.hasError = false;
      } else {
        that.user = tempUser;
      }
    })

    
  }

  deleteUser() {
    var that = this;
    let userId = this.user.userId.toString();
    this.userService.removeUserData(userId).then((success)=> {
      that.userService.removeLocalData();
      that.isComplete = true;
    }, (err) => {
      console.error(err); 
    })
  }

}
