import { Component, OnDestroy, OnInit } from '@angular/core';
import APP_CONFIG from './app.config'
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { ReversePipe } from 'ngx-pipes';
import { AnalyticsService } from './services/tracking/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ReversePipe]
})
export class AppComponent implements OnInit, OnDestroy {
  title = APP_CONFIG.APPLICATION.META_DATA.TITLE;
  urls = APP_CONFIG.APPLICATION.URL;
  routeEvent$;
  showMenu = {
    footer:  true,
    nav: true,
    startLabel: 'Start'
  };
  sectionTitle: String;
  
  constructor(private route: Router,
      private activeRoute: ActivatedRoute,
      private analytics: AnalyticsService) {
        
        let componentName = "";

        //TODO: Improve to handle multiple modules
        this.routeEvent$ = this.route.events.subscribe((event: NavigationEnd | ActivationEnd) => {
          
          //Call stack order: ActivationEnd, NavigationEnd
          if( event instanceof ActivationEnd) {
            componentName = event.snapshot.routeConfig.component.name;
          }

          if(event instanceof NavigationEnd) {
            let url = event.url;
            if(url.includes(this.urls.DASHBOARD)) {
               this.showMenu.nav = false;
               this.showMenu.footer = false;
            } else {
              this.showMenu.nav = true;
              this.showMenu.footer = true;
            }

            //Analytics details:
            let eventName = analytics.PAGE_VIEW;
            let eventDetails = analytics.buildPageViewParams(url, componentName);
            analytics.logEvent(eventName, eventDetails);  
          }
        });        
  }

  ngOnInit() {
      console.log("App Component complete loading: ", this.activeRoute.snapshot);
  }

  ngOnDestroy() {
    this.routeEvent$.unsubscribe();
  }
}