import { identifierModuleUrl } from '@angular/compiler';
import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { NgbCarousel, NgbCarouselConfig, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import CONFIG from 'src/app/app.config';
import { CarouselConfig } from 'src/app/d3/models/carouselConfig';
import { ModalConfig } from 'src/app/d3/models/modalConfig';
import { PathNavigationComponent } from 'src/app/visuals/path-navigation/path-navigation.component';
import { StepDetailsComponent } from 'src/app/visuals/step-details/step-details.component';

@Injectable({
  providedIn: 'root'
})
export class ComponentService {

  private componentsList: Map<String,any> = new Map<String,any>();
  private filterComps : Array<Function> = [
    PathNavigationComponent,
    StepDetailsComponent
  ]

  constructor(private componentFactoryResolver : ComponentFactoryResolver) { 
    //Map all the components to the dynamic list
    this.filterComps.forEach((comp: Function) => {
      this.componentsList.set(comp.name, comp);
    })
  }


  /**
   * @description This initializes a component and execute the respective methods based on configuration.
   * @param clazzName 
   * @param wizard 
   * @param execMethods 
   * @param timeout 
   * @returns 
   */
  initComponent(clazzName: String, wizard : ViewContainerRef, execMethods?: Map<String,Object>, timeout?: number): ComponentRef<any> {
    let compClazz = this.componentsList.get(clazzName);    
    timeout = !! timeout ? timeout : 0;

    if(! compClazz) {
      throw new Error(`Unable to find component class map found for: ${clazzName}`);
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(compClazz);

    this.sanitizeWizardContainer(wizard, compClazz.name);

    const component: ComponentRef<any> = wizard.createComponent(componentFactory);
    const componentInstance = component.instance;


    if(! componentInstance) {
      throw new Error(`Component not initialized due to instance being undefined: ${componentInstance}`);
    }

    //Defaults if no methods were found.
    if(! execMethods ) {
      let map = new Map<String,Object>();
      map.set("ngOnInit", []);
      execMethods = map;
    }

    execMethods.forEach( (args : Object[], methodName : string) => {
      //If there is a showNode method add this to the arguments.
      if(methodName == "showNode") {
        args.push([component])
      }

      //Execute method as part of the set timeout
      setTimeout(componentInstance[`${methodName}`](...args), timeout);
            
    });

    //TODO: possible runtime issue: If the setTimeout method takes long to execute it could impact the resposne from the server.      
    return component;
  }


  /**
   * Sanitize the wizard before inserting the container in the template
   * @param container 
   * @param name 
   */
   private sanitizeWizardContainer(container: ViewContainerRef, name?: String) {
    if(!!container && container.get(0) != null) {
      container.clear();
      console.log(`Clearing out the container ${name} another`);
    }
  }

  /**
   * Clears both the component and the container if it was found.
   * @param componentRef 
   * @param containerRef 
   */
  clearComponent(componentRef : ComponentRef<any>, containerRef?: ViewContainerRef) {
    if(!! componentRef) {
      componentRef.destroy()
    }

    //If the container reference was provided, clear that as well
    if(!! containerRef) {
      this.clearContainer(containerRef)
    }
  }

  /**
   * Remove a container from the UI and the dom.
   * @param containerRef 
   */
  private clearContainer(containerRef : ViewContainerRef) {
    if(!!containerRef && containerRef.length > 0) {
      containerRef.clear();
    }
  }


  /**
   * Set Custom Modal properties
   */
   setModalConfig(modalConfig: NgbModalConfig, selector?: string) {
    let configs : ModalConfig = new ModalConfig();

    for(const property in configs) {
      if(!! modalConfig[property]) {
        modalConfig[property] = configs[property];
      }
    }

    if(! selector || selector == undefined) {
      selector = CONFIG.APPLICATION.ADDITIONAL.MODAL_SELECTION_PARENT;
    }

    modalConfig.container = selector;
  }

   setCarouselConfig(carousel: NgbCarousel, config: NgbCarouselConfig, pause?: Boolean) {
    let slideConfig = new CarouselConfig(false, false, false, false, false);

    for(const property in slideConfig) {
      if (!! config[property]) {
        config[property] = slideConfig[property];
      }
    }
    
    // if((! slideConfig.pause) && !!carousel) {
    //   //This pauses the carousel
    //   carousel.pause();
    // }
  }
}
